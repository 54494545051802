import { CategoryType, metaData } from "../../services/meta.service";

export const CategoryPageMetaData = ({
  categoryName,
}: {
  categoryName: CategoryType;
}) => {
  const title = categoryName && metaData[categoryName]?.title;
  const description = categoryName && metaData[categoryName]?.description;
  return (
    <>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
    </>
  );
};
