import {
  divClassName,
  h2ClassName,
} from "../../single-category-css";

export const BandageSectionOne = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      {/* <h1 className={h1ClassName}>Buy skin stapler online:</h1> */}
      <div className={divClassName}>
        <h2 className={h2ClassName}>
          Types of Bandages for Every Medical Need
        </h2>
        <p>
          Bandages are necessary for wound treatment because they give support
          and protection while helping with healing. Here is a basic explanation
          of the various varieties available to satisfy different medical needs:
        </p>
        <ul className="list-disc ml-10">
          <li>
            <strong>Adhesive Bandages : </strong> It is a thin, flexible bandage
            used to treat minor cuts and scratches. They contain an absorbing
            cushion that cleans wounds, prevents infection, and ensures healing.
          </li>
          <li>
            <strong>Gauze Bandages : </strong> Gauze bandages have multiple uses
            and are formed from woven cloth. They help treat more extensive
            wounds because they give comfort and absorption. Bandages like these
            are regularly used to dress surgical incisions and deep wounds.
          </li>
          <li>
            <strong>Elastic Bandages : </strong>
            Elastic bandages are generally called compression wraps. They
            stabilize and reduce swelling because of sprains, strains, and other
            diseases. They adjust to the body, providing a tight fit.
          </li>
          <li>
            <strong>Cohesive Bandages : </strong>Cohesive bandages attach to
            themselves but not the skin. It makes them suitable for wrapping
            joints and securing dressings. Cohesive bandages provide flexibility
            and stability without the need for adhesives.
          </li>
          <li>
            <strong>Tubular Bandages : </strong> Tubular bandages are uniform
            and cylindrical, ensuring even compression and protection. They are
            broadly used on fingers, toes, and limbs to give a comfortable fit
            with no tightness.
          </li>
          <li>
            <strong>Triangular Bandages : </strong> Triangular bandages are huge
            fabric bandages that are folded into a triangle. They are adaptable
            and may be used as slings for arm injuries or to immobilize legs.
          </li>
          <li>
            <strong>Compression Bandages : </strong>Compression bandages use
            pressure to minimize swelling and promote flow. They are often used
            to treat venous ulcers and to avoid blood clots following surgery.
          </li>
          <li>
            <strong>Liquid bandages : </strong>The liquid bandage produces a
            protective barrier to tiny cuts and bruises. They help in incision
            closure, blocking dirt and bacteria from entering while allowing the
            skin to breathe.
          </li>
          <li>
            <strong>Hydrocolloid Bandages : </strong>Hydrocolloid bandages have
            a gel-forming element that keeps injuries wet and improves healing.
            They help to avoid and treat blisters, burns, and ulcers.
          </li>
          <li>
            <strong>Transparent film dressings : </strong>Transparent film
            dressings are thin, transparent bandages that stick to the skin.
            They allow convenient wound monitoring without needing removal,
            making them excellent for minor wounds or as a supplementary
            dressing.
          </li>
        </ul>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>Uses of Bandaging in Nursing</h2>
        <p>
          Bandaging is a vital nursing approach for wound care and injury
          treatment. Proper bandaging procedures prevent wounds, control
          bleeding, reduce swelling, and strengthen injured tissues. Nurses must
          be skilled in applying different bandages to guarantee patient comfort
          and promote effective recovery. Understanding types of bandaging in
          nursing processes is essential for meeting individual medical demands,
          delivering the best care, and improving recovery results.
        </p>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>Uses of Bandages</h2>
        <p>
          Bandages serve multiple essential functions in medical care. Here’s
          how they are used effectively:
        </p>
        <ul className="list-disc ml-10">
          <li>
            <strong>Wound Protection : </strong> Bandages shield wounds from
            debris, bacteria, and other impurities. This barrier facilitates
            proper wound healing by preventing infections and fostering a
            sterile healing environment.
          </li>
          <li>
            <strong>Compression : </strong> Bandages help stop blood flowing and
            reduce oedema by applying pressure. Bandages are often used in first
            aid for sprains and strains to support an injury.
          </li>
          <li>
            <strong>Immobilization : </strong>
            Bandages immobilize and limit motion in fractures or severe sprains,
            which helps in fast recovery. This immobilization is critical for
            reducing discomfort and allowing wounded tissues to heal correctly
            without further strain.
          </li>
          <li>
            <strong>Securing Dressings : </strong> Bandages securely fix medical
            dressings over wounds, ensuring they remain intact and effective.
            This keeps the dressing from slipping or falling off, which is
            essential for wound care.
          </li>
          <li>
            <strong> Burn Care : </strong> Special burn bandages give critical
            protection to burn wounds, keeping moisture and soothing the injured
            spot while preventing infection.
          </li>
        </ul>
      </div>
    </div>
  );
};
