import React, { useRef, useState, useEffect } from "react";

import { experienceServices } from "../../services/experience.services";
import { ContactCta } from "./ContactCta";
import CountUp from "react-countup";


// Custom hook for scroll trigger
const useScrollTrigger = (ref: React.RefObject<HTMLElement>, offset = 0) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const scrollObserver = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: `${offset}px` }
    );

    if (ref.current) {
      scrollObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        scrollObserver.unobserve(ref.current);
      }
    };
  }, [ref, offset]);

  return isVisible;
};

export default function Experience() {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <ContactCta />
      <div className="w-full h-screen relative overflow-hidden">
        <div className="sticky top-0 left-0 w-full h-full md:h-10/12 lg:h-8/12">
          <img
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053309/zf7zymmnzlqjcgn4nueu.webp"}
            alt="Background"
            className=" w-full h-full object-cover"
           
          />
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>
        <div
          ref={contentRef}
          className="absolute inset-0 z-10"
          style={{
            willChange: "transform",
          }}
        >
          <div className="container mx-auto p-3 lg:px-4 lg:py-8 h-full flex flex-col justify-center items-center">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[4rem] md:gap-[6rem] lg:gap-[10rem] text-white">
              {experienceServices.map((el, index) => (
                <CounterSection key={index} el={el} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CounterSection = ({ el }: { el: any }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const isVisible = useScrollTrigger(sectionRef, 100);

  return (
    <div ref={sectionRef} className="flex flex-col gap-2 md:gap-4 lg:gap-6">
      <img
        src={el.image}
        alt="ContentImage"
        className="w-8 h-8 md:w-12 md:h-12 lg:w-[100px] lg:h-[100px] mx-auto"
      />
      <div className="flex flex-col gap-2 font-extralight">
        <p className="md:text-lg lg:text-3xl mx-auto">
          {isVisible && (
            <CountUp end={parseInt(el.content)} duration={4} suffix="+" />
          )}
        </p>
        <p className="text-sm md:text-base lg:text-xl mx-auto">{el.content2}</p>
      </div>
    </div>
  );
};
