import tokenVerify from "../handlers/tokenHandler";

export const getLocalStorage = (key: string): any | null => {
  if (typeof window !== "undefined") {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }
  return null;
};

export const isAuth = async (): Promise<any | null> => {
  if (typeof window !== "undefined") {
    const userCred = getLocalStorage("nuvo_user_cred");
    if (userCred && userCred.token) {
      try {
        const response = await tokenVerify(userCred.token);
        if (response.status === 200) {
          return userCred;
        } else {
          return false;
        }
      } catch (error) {
        console.debug("Token verification failed", error);
        return false;
      }
    }
    return false;
  }
  return null;
};
