
import useWindowWidth from "../../hooks/useWindowWidth";

export const IdealSuture = () => {
  const { isMd } = useWindowWidth();
  return (
    <div className="w-10/12 flex flex-col gap-5 mx-auto my-10">
      <p className="text-2xl text-center">
        The Ideal <span className="text-bold">Suture</span>
      </p>
      <div>
        <img
          src={isMd ? "https://res.cloudinary.com/dzqi3eyia/image/upload/v1728052336/f2y8ed4xpnybjbyd0e3z.webp" : "https://res.cloudinary.com/dzqi3eyia/image/upload/v1728052314/uf9mfcamjsu5ywoebsy7.webp"}
          alt="deskimage"
          className="mx-auto h-[100vh] w-fit"
        />
      </div>
    </div>
  );
};
