import { ReactNode } from "react";

export const NuvoContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={`max-w-[1700px] mx-auto px-6 sm:px-4 md:px-4 lg:px-6 xl:px-8 h-full flex justify-center items-center`}
    >
      {children}
    </div>
  );
};
