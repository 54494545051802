import {
  divClassName,
  h1ClassName,
  h2ClassName,
} from "../../single-category-css";

export const SterileOne = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      <h1 className={h1ClassName}>Buy Sterile Dressing Online</h1>
      <div className={divClassName}>
        <h2 className={h2ClassName}>What Are Sterile Dressings?</h2>
        <p>
          Specialised medical supplies known as sterile dressings protect
          wounds, prevent infection and keep them sanitary. These dressings are
          made in immaculate conditions to prevent infection. Creating a sterile
          barrier helps faster healing and reduces the likelihood of
          complications. Sterile dressings are vital for healing surgical
          wounds, burns, and other open injuries because they provide patients
          comfort and protection. High-quality sterile dressings ensure that
          wounds are appropriately treated, improving patient care and healing.
        </p>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>Benefits of Using Sterile Dressings</h2>
        <p>
          Sterile dressings are essential in wound care because they create a
          clean, safe environment for faster healing. Using sterile bandages
          reduces the possibility of infection, crucial for preventing
          complications. These dressings absorb exudate, moisten wounds, and
          protect against external contaminants. Sterile dressings are
          incredibly gentle on the skin, decreasing irritation while leaving the
          wound intact. Sterile dressings provide unsurpassed safety and care
          for effective wound treatment and rehabilitation.
        </p>
      </div>
    </div>
  );
};
