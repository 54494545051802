export const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col gap-10 p-10 mt-7 mb-20 ">
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Privacy Policy</div>
        <p>
          By using the Nuvomedsurg website at https://www.nuvomedsurg.com (the
          “Site”), you agree to the terms outlined below. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information
          when you visit our Site. Please read this policy carefully to
          understand our views and practices regarding your data and how we
          treat it. If you do not agree with this Privacy Policy, please do not
          access or use the Site.
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          Information Collection and Use
        </div>
        <p>
          Nuvomedsurg collects information you provide directly to us, such as
          when you create an account, subscribe to a newsletter, or fill out a
          form. The types of information we may collect include your name, email
          address, phone number, and any other information you choose to
          provide.
        </p>
        <p>We use this information to:</p>
        <ul className="list-disc ml-6">
          <li>Provide, operate, and maintain our Site</li>
          <li>Improve, personalize, and expand our Site</li>
          <li>Understand and analyze how you use our Site</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, for customer service, updates, and promotional purposes
          </li>
          <li>Process your transactions and manage your orders</li>
          <li>Send you emails</li>
          <li>Detect and prevent fraud</li>
        </ul>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Cookies</div>
        <p>
          We use cookies to enhance your experience on our Site. Cookies are
          small files on your device that help us provide services and improve
          your browsing experience. We may use the following types of cookies:
        </p>
        <ul className="list-disc ml-6">
          <li>
            <span className="font-bold">Comment Cookies:</span> If you leave a
            comment on our Site, you may opt in to save your name, email
            address, and website in cookies. These cookies are for your
            convenience, so you do not have to fill in your details again when
            leaving another comment. These cookies last for one year.
          </li>
          <li>
            <span className="font-bold">Login Cookies:</span> When you visit our
            login page, we set a temporary cookie to determine if your browser
            accepts cookies. This cookie contains no personal data and is
            discarded when you close your browser. Additionally, when you log
            in, we set up several cookies to save your login information and
            screen display preferences. Login cookies last for two days, while
            screen options cookies last for a year. If you select “Remember Me,”
            your login persists for two weeks. Login cookies are removed when
            you log out of your account.
          </li>
          <li>
            <span className="font-bold">Editing/Publishing Cookies:</span> If
            you edit or publish an article, an additional cookie containing no
            personal data is saved in your browser. This cookie indicates the
            post ID of the article you edited and expires after one day.
          </li>
        </ul>
        <p>
          For further information on controlling and managing cookies on your
          device, please refer to your browser settings. Note that disabling
          cookies may impact certain functionalities of the Site.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          Information Sharing and Disclosure
        </div>
        <p>
          Nuvomedsurg does not sell, trade, or rent users' personal information
          to others. We may share generic aggregated demographic information not
          linked to any personal identification information regarding visitors
          and users with our business partners, trusted affiliates, and
          advertisers for the above purposes.
        </p>
        <p>
          In certain situations, Nuvomedsurg may be required to disclose
          personal data in response to lawful requests by public authorities,
          including to meet national security or law enforcement requirements.
          We may also disclose your information if we believe that it is
          necessary to:
        </p>
        <ul className="list-disc ml-6">
          <li>Comply with legal obligations</li>
          <li>Protect and defend our rights or property</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Site
          </li>
          <li>
            Protect the personal safety of users of the Site or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Security</div>
        <p>
          The security of your Personal Information is important to us. While we
          strive to use commercially acceptable means to protect your Personal
          Information, we cannot guarantee its absolute security.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Data Retention</div>
        <p>
          We will retain your personal information for as long as needed to
          provide services to you, comply with legal obligations, resolve
          disputes, and enforce our agreements
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          Changes to This Privacy Policy
        </div>
        <p>
          Nuvomedsurg reserves the right to update or change our Privacy Policy
          at any time. We will notify you of any changes by posting the new
          Privacy Policy on this page. Your continued use of the Site after we
          post any modifications to the Privacy Policy will constitute your
          acknowledgement of the modifications and your consent to abide by and
          be bound by the modified Privacy Policy.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          Disclaimer of Warranties and Limitation of Liability
        </div>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SITE AND ANY INFORMATION,
          PRODUCTS, OR SERVICES MADE AVAILABLE THROUGH THE SITE ARE PROVIDED "AS
          IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          NUVOMEDSURG DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
          BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, LACK OF VIRUSES, AND
          WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE.
        </p>
        <p>
          IN NO EVENT SHALL NUVOMEDSURG OR ITS AFFILIATES BE LIABLE FOR ANY
          DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
          DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS, GOODWILL, USE,
          DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THE USE
          OF THE SITE OR PRODUCTS PURCHASED THROUGH THE SITE.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          Affiliate Disclosure
        </div>
        <p>
          Certain links on our Site may lead to affiliate websites, and we may
          earn a commission on any purchases made through those links. These
          commissions come at no extra cost to you. We only promote products and
          services that we believe will add value to our users.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Refund Policy</div>
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-xl lg:text-2xl font-semibold">Eligibility</div>
            <p>
              We offer a no-questions-asked 7-day exchange or refund policy
              specifically for products. This allows our customers to return or
              exchange products within seven days of delivery, ensuring complete
              satisfaction with every purchase.
            </p>
            <p>
              To initiate a refund or exchange, please email info@nuvomedsurg.in
              with your request. Once we receive your request, a NUVO MEDSURG
              representative will reach out for further clarification before
              email approving your return. Our Operations team will arrange the
              collection of the returned product within 3 working days from the
              time of request initiation. Upon receipt of the returned item, our
              Quality Assurance (QA) and Quality Control (QC) teams will inspect
              and approve the return. Once approved, we will initiate the
              replacement process within 7 working days. This timeline ensures
              that every returned item is processed efficiently and with
              thorough quality checks.
            </p>
            <p>
              You may raise a return or replacement request for various reasons,
              including but not limited to:
            </p>
            <ul className="list-disc ml-6">
              <li>
                Physical damage to the product during transit (we encourage you
                not to accept visibly damaged packages).
              </li>
              <li>
                Manufacturing defects are particularly relevant for equipment or
                instruments.
              </li>
              <li>
                Short expiry (if the product has less than one month remaining
                until expiration for perishable items).
              </li>
              <li>Missing parts or accessories.</li>
              <li>Receipt of an incorrect product.</li>
            </ul>
          </div>
          <div>
            <div className="text-xl lg:text-2xl font-semibold">Process</div>
            <p>
              Requests for returns or replacements should be directed to
              info@nuvomedsurg.in with supporting documentation, which may
              include:
            </p>
            <ul className="list-disc ml-6">
              <li>
                Photos or videos of the product illustrating the issue or
                defect.
              </li>
              <li>A photo of the shipping label with the order ID.</li>
              <li>A photo or soft copy of the bill or invoice.</li>
            </ul>
            <p>
              Once we receive your request, a NUVO MEDSURG representative will
              contact you for any additional clarification. Please note that
              incomplete or inaccurate information may prevent your request's
              approval. Once approved, our Operations team will initiate return
              collection within 3 working days. After receiving the product, our
              QA/QC teams will inspect it. If approved, the replacement process
              will be initiated within 7 working days.
            </p>
          </div>
          <div>
            <div className="text-xl lg:text-2xl font-semibold">
              Terms and Conditions
            </div>

            <ul className="list-disc ml-6">
              <li>
                Requests should be initiated within 7 days after receiving the
                product.
              </li>
              <li>
                Replacement will be offered if the return request is approved.
                In cases where the product is out of stock, a refund may be
                issued through a payment gateway, credit note, or coupon.
              </li>
              <li>
                Certain sterile products (e.g., vaccines, resins) are eligible
                for return only if delivered incorrectly.
              </li>
              <li>
                NUVO MEDSURG reserves the right to deny replacement requests
                deemed not genuine.
              </li>
              <li>
                Returned products must meet the following compliance standards:
                <ul className="list-disc ml-6">
                  <li>Product must be complete with no missing parts</li>
                  <li>Product should be unused and undamaged.</li>
                  <li>
                    Product should not contain custom engraving, embroidery, or
                    designs specified during order placement.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl lg:text-2xl font-semibold">
              Order Cancellation Policy
            </div>
            <p>You may be eligible for order cancellation and refund if:</p>
            <ul className="list-disc ml-6">
              <li>
                The order is canceled within 6 hours and has not yet been
                shipped.
              </li>
              <li>
                Delivery is delayed beyond 21 days from the order date (this
                does not apply to custom orders, heavy equipment, devices, or
                furniture).
              </li>
            </ul>
            <p>
              Specially procured items are generally non-cancelable, although
              exceptions may be made at our discretion. NUVO MEDSURG retains the
              right to refuse cancellation requests at its discretion.
            </p>
          </div>
          <div>
            <div className="text-xl lg:text-2xl font-semibold">
              Terms and Conditions for COD Orders
            </div>

            <ul className="list-disc ml-6">
              <li>
                Delivery OTP will be sent to your registered mobile number and
                email ID.
              </li>
              <li>
                The delivery person will contact you using your registered
                mobile number only.
              </li>
              <li>
                The delivery team will make up to 3 attempts to deliver the
                item. Failure to deliver within these attempts will result in
                the shipment's return.
              </li>
              <li>
                If you receive damaged or incorrect goods, take a photo and
                share it with NUVO MEDSURG within 24 hours at
                info@nuvomedsurg.in.
              </li>
              <li>
                If you receive a delivery confirmation message without the
                goods, contact NUVO MEDSURG within 24 hours at
                info@nuvomedsurg.in.
              </li>
              <li>
                During return pickups, 2 attempts will be made. Ensure the
                pickup person seals the package in front of you.
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl lg:text-2xl font-semibold">
              Shipping Policy
            </div>
            <p>
              NUVO MEDSURG strives to ensure timely and reliable delivery of all
              orders. Standard shipping timelines are provided during checkout
              based on your location and product availability. Most orders are
              shipped within 3-10 working days from the date of purchase.
              Shipping for custom items, heavy equipment, or orders to remote
              locations may take longer. In such cases, the estimated shipping
              time will be displayed at checkout, and customers will receive
              updates on expected delivery.
            </p>
            <p>
              Shipping charges, if applicable, will be communicated at checkout
              before completing your order. NUVO MEDSURG is not responsible for
              delays caused by unforeseen circumstances, such as extreme
              weather, logistical issues, or strikes. In cases where shipment
              tracking information is provided, customers can monitor the status
              of their orders directly through the provided tracking link. If
              any delays are expected, we will make every effort to inform you
              promptly and work towards a satisfactory resolution.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Contact Us</div>
        <p>
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us at:
        </p>
        <p>Phone : +91-7082210770</p>
        <p>Email: info@nuvomedsurg.in</p>
        <p>
          Address : 104, Sector-16, HSIIDC, Bahadurgarh, Haryana - 124507,
          India.
        </p>
      </div>
    </div>
  );
};
