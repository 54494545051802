import { useEffect, useState } from "react";
import { SingleProductType } from "../../types/product.types";
import { getProductsByCategory } from "../../handlers/productHandler";
import { useNavigate, useParams } from "react-router-dom";
import { NuvoContainer } from "../conatiner/NuvoContainer";
import { BreadCrumbs } from "../shared/BreadCrumbs";
import { unslugify } from "../../utils/UnSlugify";
import { getMinAndMaxPrice } from "../../utils/getMinAndMaxPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CategoryPageMetaData } from "./CategoryMeta";
import { CategoryType } from "../../services/meta.service";
import { SurgicalSectionOne, SectionTwo } from "./surgical-sutures";
import { SterileOne, SterilTwo } from "./sterile-dressings";
import {
  DisposableSectionOne,
  DisposableSectionTwo,
} from "./surgical-disposable";
import { SkinStaplerSectionOne, SkinStaplerSectionTwo } from "./skin-stapler";
import { BandageSectionOne } from "./bandages";

import { AlwaysScrollToTop } from "../../utils/scrollToTop";
import { GlovesSectionOne, WipesSectionOne } from "./disinfectant-wipes.tsx";
import Shimmer from "../Shimmer/Shimmer";
export const SingleCategoryView = () => {
  const [productsData, setProductsData] = useState<SingleProductType[] | null>(
    null
  );
  const [isLoading, setIsloading] = useState<boolean>(false);

  const { categoryName } = useParams<{ categoryName: string }>();

  const getAllProducts = async (categoryName: string) => {
    setIsloading(true);
    try {
      const data = await getProductsByCategory(categoryName);
      setProductsData(data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AlwaysScrollToTop();
  }, []);

  useEffect(() => {
    if (categoryName) {
      getAllProducts(categoryName);
    }
  }, [categoryName]);

  const navigate = useNavigate();

  const unslugifiedCategoryName = categoryName && unslugify(categoryName);

  return (
    <NuvoContainer>
      <CategoryPageMetaData
        categoryName={unslugifiedCategoryName as CategoryType}
      />
      <div className="w-full">
        <div className="w-1/3 py-5">
          <BreadCrumbs
            breadcrumbs={[
              {
                label: "Home",
                link: "/",
              },
              {
                label: "Category",
                link: "/categories",
              },
              {
                label: unslugifiedCategoryName as string,
              },
            ]}
          />
        </div>

        <p className="text-title-5 font-bold pb-10">Shop All Products</p>

        {isLoading ? (
          <div className="w-full overflow-x-auto scrollbar-hide">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Array(10)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="cursor-pointer bg-nuvo-card w-full min-h-fit h-[480px] p-4 flex flex-col rounded-[8px]"
                  >
                    <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 pb-5 md:pb-20">
              {productsData &&
                productsData.map((product) => {
                  const { maxPrice, minPrice } = getMinAndMaxPrice(
                    product.variants
                  );

                  return (
                    <div className="cursor-pointer bg-nuvo-card w-full h-fit p-4 flex flex-col rounded-[8px]">
                      <div className="flex-shrink-0 h-[250px] md:h-[300px] flex justify-center items-center">
                        <LazyLoadImage
                          className="w-full h-full object-cover"
                          src={product.images[0]}
                          alt={product.name}
                          effect="blur"
                          loading="lazy"
                        />
                      </div>
                      <div className="flex flex-col justify-between gap-10 h-full">
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-title-6">
                            {product.name.length > 20
                              ? product.name.slice(0, 20) + "..."
                              : product.name}
                          </p>
                          <div className="flex flex-row gap-4">
                            <p className="font-medium text-title-7">
                              ₹ {minPrice}
                            </p>
                            -
                            <p className="font-medium text-title-7">
                              ₹ {maxPrice}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            navigate(
                              `/categories/${categoryName}/${product.slug}`
                            );
                          }}
                          className="text-white font-medium w-full py-3 px-10 bg-black rounded-[12px] text-center"
                        >
                          <p>Purchase Now</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {categoryName === "surgical-sutures" && <SurgicalSectionOne />}
        {categoryName === "sterile-dressings" && <SterileOne />}
        {categoryName === "surgical-disposable" && <DisposableSectionOne />}
        {categoryName === "skin-stapler" && <SkinStaplerSectionOne />}
        {categoryName === "surgical-sutures" && <SectionTwo />}
        {categoryName === "sterile-dressings" && <SterilTwo />}
        {categoryName === "surgical-disposable" && <DisposableSectionTwo />}
        {categoryName === "skin-stapler" && <SkinStaplerSectionTwo />}
        {categoryName === "bandages" && <BandageSectionOne />}
        {categoryName === "disinfectant-wipes" && <WipesSectionOne />}
        {categoryName === "gloves" && <GlovesSectionOne />}
      </div>
    </NuvoContainer>
  );
};
