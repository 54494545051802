import {
  divClassName,
  h2ClassName,
} from "../../single-category-css";

export const WipesSectionOne = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      {/* <h1 className={h1ClassName}>Buy skin stapler online:</h1> */}
      <div className={divClassName}>
        <p>
          Disinfectant wipes are an absolute necessity for maintaining a
          sanitized and germ-free atmosphere. These wipes provide a rapid and
          efficient solution to sanitize surfaces at home, work, or traveling.
          They are made to keep you and your loved ones safe by eliminating
          99.9% of germs and viruses. Are you trying to find the finest store to
          purchase antiseptic wipes? We have everything covered. Our wipes are
          long-lasting, adhere well to the skin, and come in handy packaging to
          suit all requirements. Use our premium Disinfectant Wet Wipes to
          safeguard your area right now.
        </p>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>
          How to Use All the Different Types Available
        </h2>
        <p>
          Disinfectant wipes with cleaning agents are now a part of our everyday
          hygiene routine because of the numerous options; it's crucial to know
          how to use each properly.
        </p>
        <ul className="list-disc ml-10">
          <li>
            Understanding the kinds of disinfectant wipes will assist you in
            choosing the right product for your requirements, whether you're
            buying them for your house or workplace or packing them in your
            travel bag.
          </li>
          <li>
            Quickly tidying up spaces and surfaces effectively with disinfectant
            wipes is excellent! They work well for cleaning surfaces, like
            countertops and doorknobs, while helping eliminate germs
            effectively! Just grab a wipe. Gently wipe the surface before
            letting it air dry naturally – super convenient! Additionally, these
            wipes come in handy for cleaning your hands when you can't access
            soap and water easily.
          </li>
          <li>
            When handling electronic equipment, like screens and devices, opt
            for wipes designed to safeguard their surfaces from harm. These
            wipes are alcohol-free, ensuring that sensitive surfaces remain
            unscathed after cleaning. To clean the gadget effectively, softly
            wipe it down to avoid moisture.
          </li>
          <li>
            If you want to keep your home free of germs and bacteria in high-use
            spots, like bathrooms and kitchens where germs tend to
            thrive—consider using heavy-duty disinfectant wipes! When using them
            for results, focus primarily on frequently touched areas and let the
            surface air dry after wiping it down.
          </li>
          <li>
            Knowing how to use types of disinfectant wipes can contribute to
            maintaining a cleaner and healthier environment for yourself and
            those in your vicinity. When you're prepared to replenish your
            disinfectant wipes, consider exploring manufacturers to ensure you
            choose top-quality options.
          </li>
        </ul>
      </div>
    </div>
  );
};
