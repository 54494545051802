import { ProductVariantType } from "../types/product.types";

function getMinAndMaxPrice(variants: ProductVariantType[]) {


  if (!variants || !Array.isArray(variants)) {
    return { minPrice: 0, maxPrice: 0 };
  }

  let allPrices: number[] = [];

  variants.forEach((variant) => {
    if (variant.codes && Array.isArray(variant.codes)) {
      variant.codes.forEach((code) => {
        if (typeof code.originalPrice === "number") {
          allPrices.push(code.originalPrice);
        }
      });
    }
  });

  if (allPrices.length === 0) {
    return { minPrice: 0, maxPrice: 0 };
  }

  const minPrice = Math.min(...allPrices);
  const maxPrice = Math.max(...allPrices);

  return { minPrice, maxPrice };
}

export { getMinAndMaxPrice };
