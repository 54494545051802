import axios from "axios";
import baseUrl from "./baseUrl";
import { LoginResponse, UserAddress } from "../types/user.types";

export const createAddressHandler = async (
  streetName: string,
  doorNo: string,
  city: string,
  state: string,
  pinCode: string,
  bearerToken: string,
  landMark: string | null
) => {
  console.log(streetName)
  const { data } = await axios.post(
    `${baseUrl}/address/create`,
    {
      streetName: streetName,
      doorNo: doorNo,
      city: city,
      state: state,
      pinCode: pinCode,
      landMark: landMark ? landMark : null,
    },
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return data as LoginResponse;
};

export const getAddressHandler = async (bearerToken: string) => {
  const response = await axios.get(`${baseUrl}/address/get`, {
    headers: {
      Authorization: "Bearer " + bearerToken,
    },
  });
  
  return response?.data?.data as UserAddress;
};
