const ImageCarousel = () => {
  return (
    <div className="w-full">
      <video
        className="w-full h-[20rem] md:h-[30rem] lg:h-[41rem] object-cover"
        autoPlay
        loop
        muted
      >
        <source
          src={
            "https://res.cloudinary.com/djdlol0qe/video/upload/v1724940484/nuvo-banner_xurigt.mp4"
          }
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default ImageCarousel;
