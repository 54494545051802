/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getMyOrders } from "../handlers/orderHandler";
import { MyOrderType } from "../types/orders.types";
import Accordion from "../components/orders/Accordian";
import { useNuvoContext } from "../context/NuvoContext";
import { LoginResponse } from "../types/user.types";
import { getLocalStorage } from "../helpers/authHelper";
import Shimmer from "../components/Shimmer/Shimmer";
import { NuvoContainer } from "../components/conatiner/NuvoContainer";

export default function Orders() {
  const [ordersData, setOrdersData] = useState<MyOrderType[]>([]);
  const { handleError } = useNuvoContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const ordersPerPage = 5; // You can adjust this number as needed

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse = getLocalStorage("nuvo_user_cred");
      const data = await getMyOrders(user.token);
      setOrdersData(data);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      console.log(error);
    } 
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Get current orders
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = ordersData.slice(indexOfFirstOrder, indexOfLastOrder);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div>
      {isLoading ? (
        <NuvoContainer>
        <div className="w-full overflow-x-auto scrollbar-hide">
        <div className="flex flex-col gap-4 mb-20">
        
           {Array(3)
             .fill(0)
             .map((_, index) => (
              <div key={index} className="cursor-pointer bg-nuvo-card  w-full md:w-11/12 lg:w-9/12 mx-auto p-4 flex flex-col rounded-[8px]">
                 <Shimmer w="100%" h={150} radius="4px 4px 0 0" />
               </div>
             ))}
     
         </div>
         </div>
         </NuvoContainer>
      ) : (
        <div className="mb-20">
          <div className="text-xl ml-10">Your Orders</div>
          {Array.isArray(ordersData) && ordersData.length > 0 ? (
            <>
              {currentOrders.map((order, index) => (
                <Accordion key={index} order={order} />
              ))}
              
              <div className="flex justify-center mt-4">
                {Array.from({ length: Math.ceil(ordersData.length / ordersPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-3 py-1 rounded ${
                      currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <p>No orders found.</p>
          )}
        </div>
      )}
    </div>
  );
}