import axios from "axios";
import baseUrl from "./baseUrl";
import { LoginResponse, UserDetailsType } from "../types/user.types";
import { SignUpResponse } from "../types/user.types";
export const loginUser = async (phoneNumber: string, password: string,countryCode:string,) => {
  const { data } = await axios.post(`${baseUrl}/user/login`, {
    phoneNumber: phoneNumber,
    password: password,
    countryCode:countryCode
  });
  return data as LoginResponse;
};

export const signUpUser = async (
  name: string,
  email: string,
  password: string,
  phoneNumber: string,
  countryCode:string
) => {
  try {
    const { data } = await axios.post(`${baseUrl}/user`, {
      name,
      email,
      password,
      phoneNumber,
      countryCode
    });
    return data as SignUpResponse;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data as SignUpResponse;
    }
    throw new Error("An unexpected error occurred");
  }
};

export const editUserHandler = async (
  name: string,
  email: string,
  phoneNumber: number,
  bearerToken: string,
  streetName?: string,
  pincode?: string,
  landMark?: string,
  doorNo?: string,
  city?: string,
  state?: string,
  addressId?: string
) => {
  const { data } = await axios.patch(
    `${baseUrl}/user/editUser`,
    {
      name,
      email,
      phoneNumber,
      streetName,
      pincode,
      landMark,
      doorNo,
      city,
      state,
      addressId
    },
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return data as LoginResponse;
};

export const getMyDetails = async (bearerToken: string) => {
  const { data } = await axios.get(`${baseUrl}/user/getmydetails`, {
    headers: {
      Authorization: "Bearer " + bearerToken,
    },
  });
  return data?.data as UserDetailsType;
};
