import cn from 'classnames';
import * as React from 'react';
import styles from './TextShimmer.module.css'; // New CSS file for TextShimmer

type TextShimmerProps = {
  className?: string;
  w: string | number;
  h: string | number;
  radius?: string | number;
};

export const TextShimmer: React.FC<TextShimmerProps> = ({ className, w, h, radius = '.5rem' }) => {
  return (
    <div
      className={cn([styles.TextShimmer, className])}
      style={{
        width: w,
        height: h,
        borderRadius: radius,
      }}
     
    />
  );
};

export default TextShimmer;
