import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { isAuth } from "../../helpers/authHelper";
import Logo from "../../assets/images/novoLogo.svg";
import { LoginResponse } from "../../types/user.types";
import UserButton from "./UserButton";
import { CartIcon } from "../../assets/icons/CartIcon";
import SearchIcon from "../../assets/images/SearchIcon.svg";
import axios from "axios";
import baseUrl from "../../handlers/baseUrl";
import { useProductSearch, Product } from "../../hooks/useProductSearch";
import Remove from "../../assets/images/Remove.svg";
import LoginForm from "../user/LoginForm";
import SignUpForm from "../user/SignUpForm";
import { useNuvoContext } from "../../context/NuvoContext";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useCart } from "../../context/NuvoCartContext";
export const Navbar = ({
  user,
  setRender,
  setUser,
}: {
  user: LoginResponse | null;
  setRender: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<LoginResponse | null>>;
}) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [signUpOpen, setSignUpOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [productData, setProductData] = useState<Product[]>([]);
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const filteredProducts = useProductSearch(productData, searchQuery); // Using custom hook for filtering
  const handleNavigate = useNavigate();
  const { handleError } = useNuvoContext();
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/product/`);
      if (response.status === 200) {
        setProductData(response.data.data);
      }
    } catch (error) {
      handleError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // @ts-ignore
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const result = await isAuth();
      setAuthStatus(!!result); // Update auth status based on isAuth result
    };

    checkAuth();
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const result = await isAuth();
      setAuthStatus(!!result); // Update auth status based on isAuth result
    };

    checkAuth();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSearchBox = () => {
    setSearchOpen(!searchOpen);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const navigate = useNavigate();
  const navbarData = [
    {
      label: "Home",
      link: "/",
      path: "/",
    },
    {
      label: "Shop",
      link: "/categories",
      path: "categories",
    },
    {
      label: "About Us",
      link: "/about-us",
      path: "about-us",
    },
    {
      label: "Contact Us",
      link: "/contact-us",
      path: "contact-us",
    },
  ];

  const backgroundColour = pathname === "/" ? " bg-white" : "bg-nuvo-card";
  const { isLg } = useWindowWidth();
  const { cartItemsCount } = useCart();
  const handleNavigateAndCloseMenu = (link: string) => {
    setIsOpen(false);
    navigate(link);
  };

  useEffect(() => {
    setIsOpen(false); // Closes the menu
  }, [pathname]); // Dependency on pathname to trigger the effect

  return (
    <div className="z-50">
      {!searchOpen && (
        <>
          <nav
            className={`flex  z-10 flex-col fixed  gap-2 ${backgroundColour}  mx-auto p-4 w-full ${
              !isLg && isOpen && "h-[75vh]"
            }`}
          >
            <div className="max-h-[200px]">
              <nav className="flex justify-between items-center">
                <div
                  onClick={() => navigate("/")}
                  className="text-xl font-bold cursor-pointer"
                >
                  <img src={Logo} alt="logo" className="w-full h-full" />
                </div>
                <div className="flex gap-6">
                  <div className="hidden lg:flex gap-10 ">
                    {navbarData.map((nav) => (
                      <div
                        className={`cursor-pointer text-title-7 font-bold hover:underline ${
                          pathname === nav.link ? "text-blue-400 underline" : ""
                        }`}
                        onClick={() => navigate(`${nav.link}`)}
                        key={nav.label}
                        style={{ display: searchOpen ? "none" : "block" }} // Hide nav items when search is open
                      >
                        <p>{nav.label}</p>
                      </div>
                    ))}
                  </div>

                  <div
                    className="lg:ml-20 flex gap-1 cursor-pointer "
                    onClick={toggleSearchBox}
                  >
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="w-6 h-6"
                    />
                    <p className="my-auto">Search</p>
                  </div>
                </div>
                <div className="flex flex-row gap-5 items-center">
                  {cartItemsCount > 0 ? (
                    <div className="flex flex-row gap-5 item-center align-middle justify-center">
                      <div className="relative">
                        <div
                          onClick={() => {
                            navigate("/mycart");
                          }}
                          className="relative"
                        >
                          <CartIcon />
                          {cartItemsCount > 0 && (
                            <span className="bg-red-500 text-white rounded-full px-2 py-1 text-xs absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
                              {cartItemsCount}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CartIcon />
                  )}
                  {authStatus && isLg ? (
                    <UserButton
                      user={user?.user.name || ""}
                      setUser={setUser}
                      setRender={setRender}
                    />
                  ) : (
                    <div className="hidden lg:flex p-2 w-[200px] bg-black gap-2 rounded-xl cursor-pointer justify-center items-center font-medium text-body-3">
                      <button
                        className="bg-white rounded-[4px] p-1 w-1/2 text-center cursor-pointer"
                        onClick={() => setLoginOpen(true)}
                        style={{ display: searchOpen ? "none" : "block" }} // Hide login button when search is open
                      >
                        Login
                      </button>
                      <button
                        className="w-1/2 text-white text-center cursor-pointer"
                        onClick={() => setSignUpOpen(true)}
                        style={{ display: searchOpen ? "none" : "block" }} // Hide signup button when search is open
                      >
                        Sign Up
                      </button>
                    </div>
                  )}
                </div>
                <div className="lg:hidden">
                  <button onClick={toggleMenu}>
                    <FaBars className="text-2xl" />
                  </button>
                </div>
                <div className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-t from-gray-200"></div>
              </nav>
            </div>
            {isOpen && (
              <div className="lg:hidden z-50 absolute top-0 left-0 w-full bg-white flex flex-col mt-4 space-y-4 p-5">
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="flex justify-end"
                >
                  <button onClick={toggleMenu}>
                    <img
                      src={Remove}
                      alt="Remove"
                      className="w-8 h-8 cursor-pointer"
                    />
                  </button>
                </motion.div>

                <div className="flex flex-col gap-5 mt-20 items-center">
                  {navbarData.map((nav) => (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{
                        delay: 0.1 * nav.label.length,
                        duration: 0.3,
                      }}
                      className="cursor-pointer text-title-7 font-bold hover:underline"
                      onClick={() => {
                        navigate(`${nav.link}`);
                        handleNavigate(nav.link);
                      }}
                      key={nav.label}
                    >
                      <p>{nav.label}</p>
                    </motion.div>
                  ))}
                  <div className="flex flex-col gap-4 mt-4">
                    {authStatus ? (
                      <UserButton
                        user={user?.user.name || ""}
                        setUser={setUser}
                        setRender={setRender}
                      />
                    ) : (
                      <div className="lg:hidden flex p-2 w-[200px] bg-black gap-2 rounded-xl cursor-pointer justify-center items-center font-medium text-body-3">
                        <button
                          className="bg-white rounded-[4px] p-1 w-1/2 text-center cursor-pointer"
                          onClick={() => {
                            setLoginOpen(true);
                            setIsOpen(false); // Close menu when opening login
                          }}
                        >
                          Login
                        </button>
                        <button
                          className="w-1/2 text-white text-center cursor-pointer"
                          onClick={() => {
                            setSignUpOpen(true);
                            setIsOpen(false);
                          }}
                        >
                          Sign Up
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </nav>
        </>
      )}
      {loginOpen && <LoginForm isOpen={loginOpen} setIsOpen={setLoginOpen} />}
      {signUpOpen && (
        <SignUpForm isOpen={signUpOpen} setIsOpen={setSignUpOpen} />
      )}
      <AnimatePresence>
        {searchOpen && (
          <motion.div
            initial={{ maxHeight: 0, opacity: 0 }}
            animate={{ maxHeight: "100px", opacity: 1 }}
            exit={{ maxHeight: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-nuvo-grey-500"
          >
            <div className="p-4  border-t border-black flex ">
              <div className="bg-nuvo-grey-500 flex gap-3 items-center border-2 border-black rounded-full px-8 py-4 w-1/2 mx-auto">
                <img src={SearchIcon} alt="SearchIcon" className="w-6 h-6" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="bg-nuvo-grey-500 focus:outline-none w-full"
                  placeholder="Search the Product Here...."
                />
              </div>
              <img
                src={Remove}
                alt="Remove"
                onClick={() => {
                  setSearchOpen(false);
                  setSearchQuery("");
                }}
                className="w-8 h-8 cursor-pointer"
              />
            </div>
          </motion.div>
        )}
        {searchOpen && filteredProducts.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="bg-nuvo-grey-500 mx-auto"
          >
            <div className="md:mx-auto md:w-6/12">
              {searchQuery ? (
                <p className="text-body-2 text-center">
                  No results found for "{searchQuery}"
                </p>
              ) : null}
            </div>
          </motion.div>
        )}
        {searchOpen && filteredProducts.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="bg-nuvo-grey-500 mx-auto"
          >
            <div className="md:mx-auto md:w-6/12">
              <ul className="list-none p-0 mx-auto">
                {filteredProducts.map((product, index) => (
                  <li
                    key={index}
                    className="hover:text-title-7 text-body-2 hover:font-bold cursor-pointer"
                    onClick={() => {
                      handleNavigate(
                        `/categories/${product.category.name
                          .toLowerCase()
                          .replace(" ", "-")}/${product.slug}`
                      );
                      setSearchOpen(false);
                      setSearchQuery("");
                    }}
                  >
                    {product.name}
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
