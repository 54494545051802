import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { signUpUser } from "../../handlers/userHandler";
import { toast } from "react-hot-toast";
import Logo from "../../assets/images/novoLogo.svg";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { NuvoDrawer } from "../nuvo-drawer/NuvoDrawer";
import { useNuvoContext } from "../../context/NuvoContext";
import { countryCode } from "../../utils/countryCode";
export type SignUpInitialValuesType = {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  countryCode: string;
};

const SignUpForm = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setRender } = useNuvoContext();
  const [filteredCountryCode, setFilteredCountryCode] = useState(countryCode);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [isPhoneNumberEnabled, setIsPhoneNumberEnabled] = useState(false);
  const initialValues: SignUpInitialValuesType = {
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    countryCode: selectedCountryCode,
    
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name Required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email Address Required"),
    password: Yup.string().required("Password Required"),
    phoneNumber: Yup.string().required("Phone Number Required"),
  });
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const { isLg } = useWindowWidth();
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSelectedCountryCode(searchTerm);

    const filtered = countryCode.filter(
      (country) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        country.dial_code.includes(searchTerm)
    );
    setFilteredCountryCode(filtered);
    setShowDropdown(true);
  };

  const handleSelect = (dialCode: string) => {
    setSelectedCountryCode(dialCode);
    setShowDropdown(false);
    setIsPhoneNumberEnabled(true); // Enable phone number field once a country code is selected
  };
  return (
    <NuvoDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      direction={isLg ? "right" : "bottom"}
      title="Sign Up"
    >
      <div className="flex justify-center h-screen bg-gray-100 p-6">
        <div className="bg-white p-8 rounded-lg shadow-md w-full h-fit">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                const data = await signUpUser(
                  values.name,
                  values.email,
                  values.password,
                  values.phoneNumber,
                  values.countryCode
                );
                if (data.responseCode === "SUCCESS") {
                  toast.success("User Registered Successfully");
                  localStorage.setItem("nuvo_user_cred", JSON.stringify(data));
                  setRender((prev) => !prev);
                  setIsOpen(false);
                } else if (data.responseCode === "PHONE_NUMBER_ALREADY_EXIST") {
                  toast.error("User already exists with this Phone Number");
                } else if (data.responseCode === "EMAIL_ALREADY_EXIST") {
                  toast.error("User already exists with this Email ID");
                } else {
                  toast.error("Something went wrong");
                }
              } catch (error) {
                toast.error("An unexpected error occurred");
              }
            }}
          >
            {({ setFieldValue }) => (
            <Form className="space-y-4 mx-auto">
              <div className="flex flex-col gap-5 p-2">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-5 items-center">
                    <img src={Logo} className="w-16 h-16" alt="login-logo" />
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <label htmlFor="name" className="text-lg font-medium">
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className="h-10 rounded-lg border px-3 focus:outline-none focus:border-blue-500"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm font-medium"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="email" className="text-lg font-medium">
                      Email
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      className="h-10 rounded-lg border px-3 focus:outline-none focus:border-blue-500"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm font-medium"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="phoneNumber"
                      className="text-lg font-medium"
                    >
                      Phone Number
                    </label>
                    <div className="flex flex-row items-center gap-2">
                      <div className="relative w-40">
                        <input
                          type="text"
                          className="h-10 rounded-lg border px-3 w-full focus:outline-none focus:border-blue-500"
                          value={selectedCountryCode}
                          onClick={() => setShowDropdown(!showDropdown)}
                          onChange={handleFilter}
                        />
                        {showDropdown && (
                          <ul className="absolute z-10 bg-white border w-full max-h-40 overflow-y-auto">
                            {filteredCountryCode.map((country) => (
                              <li
                                key={country.code}
                                className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                                onClick={() => {
                                  handleSelect(country.dial_code);
                                  setFieldValue(
                                    "countryCode",
                                    country.code
                                  );
                                }}
                              >
                                {country.name} ({country.dial_code})
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <span className="text-gray-500">|</span>
                      <Field
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        className={`h-10 rounded-lg border px-3 flex-grow focus:outline-none focus:border-blue-500 ${
                          !isPhoneNumberEnabled
                            ? "bg-gray-100 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={!isPhoneNumberEnabled} // Disable if country code is not selected
                      />
                    </div>
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-red-500 text-sm font-medium"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="password" className="text-lg font-medium">
                      Password
                    </label>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className="h-10 rounded-lg border px-3 focus:outline-none focus:border-blue-500"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm font-medium"
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-black text-white py-2 rounded-lg font-medium hover:bg-blue-600 transition-colors duration-300 w-6/12 mx-auto mt-4"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </Form>
            )}
          </Formik>
        </div>
      </div>
    </NuvoDrawer>
  );
};

export default SignUpForm;
