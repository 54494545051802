import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { createAddressHandler } from "../../handlers/addressHandler";
import { LoginResponse } from "../../types/user.types";
import { getLocalStorage } from "../../helpers/authHelper";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { NuvoDrawer } from "../nuvo-drawer/NuvoDrawer";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNuvoContext } from "../../context/NuvoContext";

type AddressValues = {
  streetName: string;
  pinCode: string;
  landMark: string;
  doorNo: string;
  city: string;
  state: string;
};

type AddressErrors = {
  [K in keyof AddressValues]?: string;
};

export const UserAddressFlyIn: React.FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}> = ({ isOpen, setIsOpen, onClose }) => {
  const [values, setValues] = useState<AddressValues>({
    streetName: "",
    doorNo: "",
    city: "",
    state: "",
    pinCode: "",
    landMark: "",
  });

  const [errors, setErrors] = useState<AddressErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLg } = useWindowWidth();
  const { setRender, handleError } = useNuvoContext();

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const validate = (values: AddressValues): AddressErrors => {
    const errors: AddressErrors = {};
    if (!values.streetName) errors.streetName = "Street name is required";
    if (!values.pinCode) errors.pinCode = "Pincode is required";
    if (!values.doorNo) errors.doorNo = "Door Number is required";
    if (!values.city) errors.city = "City is required";
    if (!values.state) errors.state = "State is required";
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = validate(values);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
        if (user?.token) {
          const data = await createAddressHandler(
            values.streetName,
            values.doorNo,
            values.city,
            values.state,
            values.pinCode,
            user.token,
            values.landMark || null
          );
          if (data) {
            toast.success("Address Created Successfully");
            setRender((prev) => !prev);
            setIsOpen(false);
          }
        }
      } catch (e: unknown) {
        handleError(e);
        if (e instanceof AxiosError) {
          toast.error(e.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className={`${!isLg && "w-full min-h[70vh]"}`}>
      <NuvoDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Address Addition"
        direction={isLg ? "right" : "bottom"}
      >
        <div className="flex gap-2 flex-col p-2 h-full bg-surface-grey">
          <div className="bg-white flex flex-col gap-3 p-4 rounded-[12px]">
            {Object.entries(values).map(([key, value]) => (
              <div key={key} className="flex flex-col gap-2">
                <label className="text-body-1 font-medium" htmlFor={key}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <input
                  className="h-10 border border-black rounded-[8px] p-2"
                  type="text"
                  id={key}
                  name={key}
                  value={value}
                  onChange={handleChange}
                />
                {errors[key as keyof AddressValues] && (
                  <div className="text-danger text-body-1 font-medium">
                    {errors[key as keyof AddressValues]}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="border-grey-200 fixed bottom-0 left-0 right-0 flex flex-row gap-2 border-t-2 bg-white px-7 py-3 lg:absolute">
            <button
              className="text-white hover:bg-success hover:text-black rounded-[8px] bg-black py-2 w-full font-medium text-title-6"
              type="button"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save Changes"}
            </button>
            <button
              className="text-danger hover:bg-danger rounded-[8px] hover:text-white py-2 w-full font-medium text-title-6"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Discard
            </button>
          </div>
        </div>
      </NuvoDrawer>
    </div>
  );
};
