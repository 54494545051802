import React, { useState, ChangeEvent, FormEvent } from "react";
import { toast } from "react-hot-toast";
import axios, { AxiosError } from "axios";
import PasswordButton from "./PasswordButton";
import baseUrl from "../../handlers/baseUrl";
import { sendForgotPasswordLink } from "../../config/passwordMail.config";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!inputs.email) {
      toast.error("Please fill the required field");
    } else {
      try {
        setIsLoading(true);
        const { data } = await axios.post(`${baseUrl}/user/forgotPassword`, {
          email: inputs.email,
        });
       
        setIsLoading(false);
        await sendForgotPasswordLink(data?.message)
        toast.success(data.message || "Reset Password Link Sent Successfully");
      } catch (error) {
        setIsLoading(false);
        const axiosError = error as AxiosError<{ message: string }>;
        toast.error(axiosError?.response?.data?.message || "Something went wrong");
      }
    }
  };

  return (
    <div className="flex flex-col gap-3 md:w-6/12 mx-auto mb-20">
      <p className="text-4xl text-center text-black font-semibold">
        Forgot Password?
      </p>
      <p className="text-xl text-center text-black">
        Don't worry we are here to help you
      </p>
      <label className="text-black text-lg text-left">
        Please Enter Your Email:
      </label>
      <input
        type="email"
        className="p-4 text-black text-sm h-10 rounded-lg border-2 border-black"
        placeholder="Enter your Email"
        value={inputs.email}
        name="email"
        onChange={handleChange}
      />
      <PasswordButton
        text="Submit"
        isLoading={isLoading}
        handleClick={handleSubmit}
        className="p-3 rounded-lg bg-blue-200 text-base md:w-6/12 xl:w-3/12 mx-auto mt-2"
      />
    </div>
  );
}
