import {
  FooterMailIcon,
  FooterPhoneIcon,
  MapIcon,
} from "../assets/icons/ContactIcons";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { sendContactMail } from "../config/contact.config";

interface FormValues {
  name: string;
  phoneNumber: string;
  email: string;
  enquiry: string;
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  enquiry: Yup.string().optional(),
});
export const ContactUs = () => {
  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      await sendContactMail(
        values.name,
        values.phoneNumber,
        values.email,
        values.enquiry
      );
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="grid lg:grid-cols-2 gap-5 w-8/12 m-auto mb-10">
      <div className="flex flex-col gap-10 bg-nuvo-grey shadow-xl p-6 rounded-sm">
        <div className="flex gap-2 items-center">
          <MapIcon />
          <p>104, Sector-16, HSIIDC, Bahadurgarh, Haryana - 124507, India.</p>
        </div>
        {/* <div className="flex gap-2 items-center">
          <MapIcon />
          <p> M-59/3, Shastri Nagar, Delhi - 110052, India.</p>
        </div> */}
        <div className="flex gap-2 items-center ml-2">
          <FooterPhoneIcon />
          <p>+91-7082210770, +91-7082210771</p>
        </div>
        <div className="flex gap-2 items-center ml-2">
          <FooterMailIcon />
          <p>info@nuvomedsurg.in</p>
        </div>
      </div>
      <div className="flex flex-col gap-10 bg-nuvo-grey shadow-xl p-6 rounded-sm">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
        <Formik
          initialValues={{ name: "", phoneNumber: "", email: "", enquiry: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-4">
              <div>
                <Field
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <Field
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <Field
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <Field
                  as="textarea"
                  name="enquiry"
                  id="enquiry"
                  placeholder="enquiry"
                  cols="30"
                  rows="3"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="enquiry"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
