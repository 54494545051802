import {
  divClassName,
  h2ClassName,
} from "../../single-category-css";

export const DisposableSectionTwo = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      <div className={divClassName}>
        <h1 className={h2ClassName}>Uses of Surgical Disposable</h1>
        <p>
          Surgical disposables are necessary to provide a secure environment
          during your medical procedures. Our top quality Robes, gloves, masks,
          and drapes are single-use items designed to prevent
          cross-contamination and diseases. We ensure a sterile atmosphere by
          shielding patients and medical professionals from potentially
          hazardous microorganisms. Surgical disposables help keep bandages and
          wound dressings clean. Our surgical basics reduce the danger of
          infection and enable safe disposal after use, which also helps with
          waste management. Surgical disposables are important in the healthcare
          industry because they protect patients and assist in lowering
          infection rates.
        </p>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>
          Why Nuvo Medsurg Surgical Disposable Products Stand Out: Unmatched
          Quality and Safety
        </h2>
        <p>
          We provide an extensive selection of disposable surgical items for
          optimal effectiveness, safety, and cleanliness in medical settings.
          Surgical disposable goods from Nuvo Medsurg are essential in every
          healthcare environment since they are designed to meet the most
          significant safety requirements.
        </p>
        <ul className="list-disc ml-10">
          <li>
            <strong>Premium Materials:</strong> Nuvo Medsurg employs only the
            best materials to ensure that each surgical disposable product is
            durable and practical. These materials provide excellent protection
            in medical situations.
          </li>
          <li>
            <strong>Rigorous Testing:</strong> Our products use strict quality
            control methods to guarantee that they meet international safety
            requirements. This assures constant functioning while reducing the
            danger of infection.
          </li>
          <li>
            <strong>Innovative Design:</strong> Our surgical disposables are
            designed for optimal comfort and practicality, allowing healthcare
            professionals to perform successfully and safely.
          </li>
          <li>
            <strong>Commitment to Safety:</strong> Nuvo Medsurg prioritises
            safety, offering products that safeguard patients and healthcare
            staff while maintaining a clean and secure workplace.
          </li>
        </ul>
      </div>
    </div>
  );
};
