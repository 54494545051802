export const SurgicalSectionOne = () => {
  return (
    <div>
      <h1 className="text-lg md:text-2xl text-center font-bold">
        Buy Suture online India
      </h1>
      <h2 className=" text-md md:text-2xl text-center font-medium my-5">
        Purchase the largest selection of surgical sutures at the most
        affordable prices from Nuvo Medsurg.
      </h2>
      <div className="text-justify flex flex-col gap-4 my-5 text-slate-500 text-sm md:text-lg">
        <p>
          <strong>Surgical sutures</strong> are vital healthcare devices used
          for closing wounds and incisions and also promoting healing. These
          sutures come in a variety of materials and are designed to bear the
          severe requirements of surgical procedures in order to promote healthy
          and fast wound healing. Surgical sutures are made strong and durable
          in order to
          <strong>support tissues during recovery</strong>. They are created
          with the aim to withstand the body's forces and movements while
          avoiding breaking or causing damage to the surrounding tissues. This
          support is critical for keeping the wound aligned and stable,
          preventing dehiscence (reopening). That is why our High-quality
          sutures are intended to be as pleasant as feasible for patients.
        </p>
        <p>
          Surgical sutures are known for being versatile, biocompatible, and
          strong. Sutures are made to be biocompatible to prevent uncomfortable
          responses; they are made to be flexible to move with the body's
          natural motions and strong enough to keep tissue together under any
          stress. Surgical sutures play an important role in healthcare,
          ensuring the success of the operations and patients' recovery.
        </p>
      </div>
    </div>
  );
};
