import { ColorRing} from "react-loader-spinner";

export const Loader = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="absolute inset-0 bg-black opacity-50 backdrop-blur-sm"></div>
      <div className="relative z-10">
        <ColorRing width="200" colors={['#5BBCFF', '#5BBCFF', '#5BBCFF', '#5BBCFF', '#5BBCFF']} />
      </div>
    </div>
  );
};
