import emailjs from "@emailjs/browser";


export const sendForgotPasswordLink = async (
  message:string
) => {
  try {
    

    // Create a form element
    const form = document.createElement("form");

    // Add input fields to the form
    const fields = {
      message:message
    };

    Object.entries(fields).forEach(([name, value]) => {
      const input = document.createElement("input");
      input.type = "text";
      input.name = name;
      input.value = value;
      form.appendChild(input);
    });

    // Send the email
    const result = await emailjs.sendForm(
      "service_mumrn28",
      "template_b7gr7qd",
      form,
      "495iS5Gp1ZhoixcHY"
    );

    console.log("Confirmation email sent successfully", result.text);
  } catch (error) {
    console.error("Error sending confirmation email:", error);
  }
};
