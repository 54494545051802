import { useNavigate } from "react-router-dom";
import { CategoryDataType } from "../../types/category.types";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ShopByCategorySection = ({ data }: { data: CategoryDataType }) => {
  const navigate = useNavigate();

  return (
    <div className="w-11/12 mx-auto flex flex-col gap-4">
      <p className="text-title-6 md:text-title-5 font-medium">Shop by Offers:</p>
      <div className="flex flex-row w-full overflow-x-auto scrollbar-hide gap-4">
        {data?.slice(0, 4).map((category) => {
          return (
            <div
              onClick={() => navigate(`/categories/${category.slug}`)}
              className="bg-nuvo-card shadow-md flex-1 h-[250px] md:h-[300px] flex flex-col items-center rounded-[8px] p-4"
            >
              <div className="flex-shrink-0 h-[200px] w-[200px] md:h-[250px] md:w-[250px] flex justify-center items-center">
                <LazyLoadImage
                  className="w-full h-full object-shrink"
                  src={category.image}
                  alt={category.name}
                  effect="blur"
                  loading="lazy"
                />
              </div>
              <p className="font-medium text-base md:text-title-6">{category.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
