import {
  divClassName,
  h1ClassName,
  h2ClassName,
} from "../../single-category-css";

export const GlovesSectionOne = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      <h1 className={h1ClassName}>
        Affordable Surgical Gloves Price for Quality and Safety
      </h1>
      <p>
        Get trustworthy supplies for affordable, premium surgical gloves for
        your medical needs. These surgical gloves guarantee the highest level of
        safety, protecting patients and healthcare professionals equally. These
        gloves are made of durable and comfortable resources and provide a snug
        fit and a firm grip, ensuring accuracy when performing operations. Our
        gloves satisfy the best sanitation and hygiene standards, whether you
        are doing delicate medical activities or performing surgeries. With
        various sizes and resources available, including nitrile and latex, we
        promise you have the right pair of gloves for the job. Safety and
        quality do not have to be sacrificed to keep costs down. Invest in
        safety and quality without breaking the bank. Purchase from us today to
        notice the difference in our reliable and reasonably priced surgical
        gloves.
      </p>
      <div className={divClassName}>
        <h2 className={h2ClassName}>Surgical Gloves Uses :</h2>
        <ul className="list-disc ml-10">
          <li>
            <strong>Protection from Infections:</strong> Surgeons and medical
            staff wear surgical gloves to prevent infections during surgeries.
            They protect both the patient and the medical individuals.
          </li>
          <li>
            <strong>Maintaining Sterility:</strong> Gloves provide a sterile
            barrier between the hands and the surgical area, limiting the
            possibility of contamination.
          </li>
          <li>
            <strong>Handling Hazardous Materials:</strong> Surgical gloves
            protect medical experts from harmful chemicals or substances.
          </li>
          <li>
            <strong>Preventing Cross-Contamination:</strong> Gloves help
            restrict the spread of germs between patients, creating a safer
            environment.
          </li>
          <li>
            <strong>Ensuring Cleanliness:</strong> Wearing gloves keeps your
            hands clean, which is mandatory in medical surroundings for
            maintaining hygiene.
          </li>
          <li>
            <strong>Comfortable Fit for Precision:</strong> Surgical gloves
            provide a snug fit, allowing surgeons to execute complex operations
            accurately and confidently.
          </li>
        </ul>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>Types of Surgical Gloves :</h2>
        <ul className="list-disc ml-10">
          <li>
            <strong>Latex Surgical Gloves:</strong> Gloves made of latex offer
            great comfort and flexibility. Because of their high touch
            sensitivity, surgeons prefer them. There are substitutes available,
            though, as some people are allergic to latex.
          </li>
          <li>
            <strong>Nitrile Surgical Gloves:</strong> For individuals allergic
            to latex, nitrile gloves are a beneficial choice. They provide good
            chemical and puncture resistance. Despite being slightly less
            elastic than latex, these gloves still fit well.
          </li>
          <li>
            <strong>Vinyl Surgical Gloves:</strong> Vinyl gloves are an
            affordable choice. While not as strong as nitrile or latex gloves,
            they are always effective for quick procedures. Additionally, they
            are free of latex, so these gloves are appropriate for allergic
            people.
          </li>
          <li>
            <strong>Polyisoprene Surgical Gloves:</strong> With no concern for
            allergies, polyisoprene gloves have a similar feel to latex gloves.
            They are ideal for many surgeons due to their extreme comfort and
            elasticity.
          </li>
        </ul>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}> How to Wear Surgical Gloves :</h2>
        <ul className="list-disc ml-10">
          <li>
            <strong>STEP I:</strong> Begin by washing your hands with soap and
            water. Ensure the gloves are completely dry to avoid any residual
            moisture that can cause discomfort or roughness.
          </li>
          <li>
            <strong>Step II:</strong> Make sure the gloves fit snugly onto your
            hands. If they are too tight, they could tear; if they are too
            loose, they could fall off or make it difficult to work.
          </li>
          <li>
            <strong>Step III:</strong> Check each glove for scratches or
            punctures before wearing it. Get yourself a new pair if you spot any
            defects.
          </li>
          <li>
            <strong>Step IV:</strong> Hold the glove by the cuff and gently
            slide your hand in, ensuring the fingers line up properly.
          </li>
          <li>
            <strong>Step V:</strong> Pick up the second glove by its cuff with
            the gloved hand. With care, slide your second hand inside without
            making contact with the exterior.
          </li>
          <li>
            <strong>Step VI:</strong> Wiggle your fingers to verify that the
            gloves are comfortable. Clear any wrinkles or folds.
          </li>
          <li>
            <strong>Step VII:</strong> Confirm that both gloves are fastened and
            undamaged by looking at them. Now that you are prepared go to work.
          </li>
        </ul>
      </div>
    </div>
  );
};
