
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
export const WideRange = () => {
  const navigate = useNavigate();
  return (
    <div className="w-11/12 mx-auto flex flex-col gap-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
        <LazyLoadImage
          className="max-h-[500px]"
          alt=""
          src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053310/pk1yuqxk7wwnrugeidsl.webp"}
          loading="lazy"
          effect="blur"
        />
        <div className="flex flex-col gap-10">
          <h2 className="text-xl md:text-2xl lg:text-3xl text-justify">
            We are Wide Range of Surgical Sutures manufacturer
          </h2>
          <p className="text-base md:text-xl text-slate-500 text-justify">
            Among surgical suture manufacturers in India, Nuvo Medsurg stands
            out for its superior products. Nuvo Medsurg provides a wide choice
            of surgical sutures and ligatures for all your medical needs. Our
            medical sutures guarantee precision and strength in each stitch,
            with options ranging from absorbable to non-absorbable. Our
            dedication to quality and innovation guarantees healthcare
            professionals can rely on our products to deliver safe and
            successful surgeries.
          </p>
        </div>
      </div>
      <hr className="border border-black w-full mx-auto"></hr>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
        <div className="flex flex-col gap-10">
          <h2 className="text-xl md:text-2xl lg:text-3xl text-justify">
            We Supply all types of surgical suture
          </h2>
          <div className="text-justify flex text-base md:text-xl flex-col gap-5 text-slate-500">
            <p>
              Our surgical dressings provide better protection and support for
              successful wound healing in various medical cases. Nuvo Medsurg
              offers an abundance of medical-surgical instruments that match our
              superior suture solutions. Nuvo Medsurg provides a variety of
              surgical sutures, including absorbable, non-absorbable, synthetic,
              and natural choices. Our selection includes:
            </p>
            <ul className="list-disc ml-5">
              <li>Monofilament and multifilament medical sutures.</li>
              <li>
                Offering the best fit for any surgical dressing —trust Nuvo
                Medsurg for the finest sutures.
              </li>
              <li>Ensuring accuracy and dependability in every treatment.</li>
            </ul>
            <p>
              Alongside sutures, Nuvo Medsurg excels as a surgical dressing
              manufacturer, offering comprehensive solutions for wound care.
              Nuvo Medsurg provides various medical-surgical instruments to
              ensure complete care during surgical procedures.
            </p>
          </div>
        </div>
        <LazyLoadImage
          className="max-h-[500px]"
          alt=""
          src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051167/f8rnmfyyuw3uvdqh1ptl.webp"}
          loading="lazy"
          effect="blur"
        />
      </div>
      <div
        onClick={() => navigate("/categories")}
        className="cursor-pointer bg-black px-10 py-2 rounded-[8px] w-fit mx-auto text-white"
      >
        <p className="text-xl text-center">View All Products</p>
      </div>
    </div>
  );
};
