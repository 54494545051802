import { LazyLoadImage } from "react-lazy-load-image-component";


export const WhyUs = () => {


  const data = [
    {
      title: "Unmatched Quality:",
      descritpion:
        "As a leading surgical suture manufacturer in India, Nuvo Medsurg offers top-notch quality using premium materials and advanced techniques. Our products satisfy strict international standards, ensuring dependability and accuracy in every surgical procedure.",
    },
    {
      title: "Innovative Solutions:",
      descritpion:
        "As leading surgical suture manufacturers, we focus on innovation to address the changing needs of medical professionals. Our innovative absorbable and non-absorbable sutures have been designed for more remarkable performance and enhanced surgical results.",
    },
    {
      title: "Dedicated Support:",
      descritpion:
        "Nuvo Medsurg has dedicated itself to delivering excellent customer service to our clients. We provide experienced guidance and timely help, guaranteeing that healthcare professionals and businesses can rely on us for smooth integration and the best product performance.",
    },
  ];

  return (
    <div>
      <p className="text-2xl md:text-3xl lg:text-4xl my-10 text-center">Why Choose Nuvo Medsurg?</p>
      <div className="text-justify w-10/12 h-fit mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-10">
        <div className="text-base md:text-lg lg:text-xl flex flex-col gap-5">
          {data.map((d) => (
            <p className="text-slate-500" key={d.title}>
              <span className="font-bold text-black">{d.title}</span>{" "}
              {d.descritpion}
            </p>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-5 mx-auto">
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051839/gqn2qno1oowzhvsu5ver.webp"}
            alt={"whyChooseUsImage1"}
            className="w-full h-full object-contain"
            effect="blur"
            loading="lazy"
          />

          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051838/shuqa9z0kcpwyhmahhi0.webp"}
            alt={"whyChooseUsImage4"}
            className="w-full h-full object-contain"
            effect="blur"
            loading="lazy"
          />
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051838/ve6h6mnjlsrlxanexlmb.webp"}
            alt={"whyChooseUsImage3"}
            className="hidden md:block w-full h-full object-contain"
            effect="blur"
            loading="lazy"
          />
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051838/n8zeu3cftjybop5k1aio.webp"}
            alt={"whyChooseUsImage5"}
            className="hidden md:block w-full h-full object-contain"
            effect="blur"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};
