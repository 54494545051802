/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getAllCategories } from "../../handlers/categoryHandler";

import {
  CategoryDataType,
  SingleCategoryType,
} from "../../types/category.types";
import { NuvoContainer } from "../conatiner/NuvoContainer";
import { useNavigate } from "react-router-dom";

import { BreadCrumbs } from "../shared/BreadCrumbs";
import { useNuvoContext } from "../../context/NuvoContext";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Shimmer from "../Shimmer/Shimmer";

export const CategoryView = () => {
  const [categoryData, setCategoryData] = useState<CategoryDataType | null>(
    null
  );
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { handleError } = useNuvoContext();
  const getAllCategoriesData = async () => {
    setIsloading(true);
    try {
      const data = await getAllCategories();
      setCategoryData(data);
      setIsloading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllCategoriesData();
  }, []);

  const navigate = useNavigate();

  return (
    <NuvoContainer>
      <div className="w-full">
        <div className="w-1/3 py-5">
          <BreadCrumbs
            breadcrumbs={[
              {
                label: "Home",
                link: "/",
              },
              {
                label: "Category",
                link: "/categories",
              },
            ]}
          />
        </div>
        <p className="text-title-5 font-bold pb-10">Shop By Categories</p>
        {isLoading ? (
          <div className="w-full overflow-x-auto scrollbar-hide">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {Array(10)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="cursor-pointer bg-nuvo-card shadow-md flex-1 flex p-6 flex-col items-center rounded-[8px]"
                  >
                    <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {categoryData &&
              categoryData.map((category) => (
                <CategoryItem category={category} />
              ))}
          </div>
        )}
      </div>
    </NuvoContainer>
  );
};

const CategoryItem = ({ category }: { category: SingleCategoryType }) => {
  const navigate = useNavigate();

  const imageUrl = category.image;

  return (
    <div
      onClick={() => navigate(`/categories/${category.slug}`)}
      className="cursor-pointer bg-nuvo-card p-6 flex flex-col items-center justify-center rounded-[8px] w-11/12 mx-auto md:w-[300px] md:h-[300px]"
    >
      <LazyLoadImage
        src={imageUrl}
        alt={category.name}
        className={`w-full h-full object-contain `}
        effect="blur"
        loading="lazy"
      />

      <p className="font-medium text-title-7 mt-2">{category.name}</p>
    </div>
  );
};
