import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../../types/user.types";
import { MyProfileFlyIn } from "../loggedUser/ProfileFlyIn";

export default function UserButton({
  user,
  setRender,
  setUser,
}: {
  user: string;
  setRender: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<LoginResponse | null>>;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("nuvo_user_cred");
    window.location.reload()
    setRender((prev) => !prev);
    setUser(null);
    navigate("/");

  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} className="relative text-white">
      <button
        className="bg-black text-white font-semibold py-2 px-4 rounded-full"
        onClick={toggleDropdown}
      >
        {user.split(" ").map((name) => name.charAt(0).toUpperCase())}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-black border border-gray-200 rounded shadow z-50">
          <ul className="list-none">
            <li
              onClick={() => setIsMyProfileOpen(true)}
              className="py-2 px-4 hover:bg-surface-grey hover:text-black cursor-pointer font-medium text-sm"
            >
              My Profile
            </li>
            <li
              className="py-2 px-4 hover:bg-surface-grey hover:text-black cursor-pointer font-medium text-sm"
              onClick={() => navigate("/orders")}
            >
              My Orders
            </li>
            <li
              className="py-2 px-4 hover:bg-surface-grey hover:text-black cursor-pointer font-medium text-sm"
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
      {isMyProfileOpen && (
        <MyProfileFlyIn
          isOpen={isMyProfileOpen}
          setIsOpen={setIsMyProfileOpen}
        />
      )}
    </div>
  );
}
