import React, { Dispatch, ReactNode, SetStateAction } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import useWindowWidth from "../../hooks/useWindowWidth";

type DrawerDirection = "right" | "bottom";

export const NuvoDrawer = ({
  isOpen,
  setIsOpen,
  children,
  direction = "right",
  title,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  direction?: DrawerDirection;
  title: string;
  children: ReactNode;
}) => {
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { isLg } = useWindowWidth();
  return (
    <Drawer
      className="bg-nuvo-grey lg:min-w-[650px] overflow-hidden"
      open={isOpen}
      onClose={toggleDrawer}
      direction={direction}
      lockBackgroundScroll={true}
      size={isLg ? 650 : "75vh"}
    >
      <div className="bg-white h-[80px] border-b border-black flex items-center justify-between px-5">
        <p className="font-medium text-black text-title-5">{title}</p>
        <div onClick={() => setIsOpen(false)} className="cursor-pointer">
          <CloseIcon />
        </div>
      </div>
      <div className="overflow-y-auto h-[calc(100vh-10px)]">{children}</div>
    </Drawer>
  );
};
