export const metaData: CategorywiseMeta = {
  "ADHESIVE TAPES": {
    title: "Adhesive Tapes Title",
    description: "Description for Adhesive Tapes",
  },
  BANDAGES: {
    title:
      "Buy Bandages Products Online in India | Buy Bandages Online -Healthcare Supplies",
    description:
      "Order Bandages online & get upto 60% OFF on Brand Name. Check out Bandages benefits and specifications. Also, get doorstep delivery PAN India. ",
  },
  "DISINFECTANT WIPES": {
    title: " Buy Disinfectant Wipes | Top Disinfectant Wet Wipes Online",
    description:
      "Shop premium disinfectant wipes and disinfectant wet wipes online. Get the best prices and fast delivery for a cleaner, safer environment.",
  },
  GLOVES: {
    title: "Gloves Title",
    description: "Description for Gloves",
  },
  "NON-STERILE DRESSINGS": {
    title: "Non-Sterile Dressings Title",
    description: "Description for Non-Sterile Dressings",
  },
  "STERILE DRESSINGS": {
    title: "Buy Sterile Dressings Online - Quality & Affordability | Shop Now",
    description:
      " Discover high-quality sterile dressings at affordable prices. Shop online for a wide selection and enjoy convenient, reliable purchasing. Click here to get the best deals and enhance your medical supplies today!",
  },
  "SURGICAL DISPOSABLE": {
    title: "Buy Surgical Disposable Products Online| Nuvo Medsurg ",
    description:
      " Explore our wide range of surgical disposable products for maximum safety and hygiene. Shop now for top-quality surgical disposables and enhance your medical practice.",
  },
  "SURGICAL SUTURES": {
    title: "Premium Surgical Sutures Online - Affordable Prices",
    description:
      "Discover a vast choice of high-quality surgical sutures at unbeatable prices. Shop online in India for exceptional value and top-notch performance for all your medical needs.",
  },
  "SKIN STAPLER": {
    title:
      "Buy Skin Stapler Online- High-Quality Surgical Staplers at Best Price",
    description:
      " Shop high-quality skin staplers online for precise and efficient wound closure. Explore our range of affordable surgical staplers. Fast shipping and secure checkout.",
  },
};

type HtmlMetaType = {
  title: string;
  description: string;
};

export type CategoryType =
  | "ADHESIVE TAPES"
  | "BANDAGES"
  | "DISINFECTANT WIPES"
  | "GLOVES"
  | "NON-STERILE DRESSINGS"
  | "STERILE DRESSINGS"
  | "SURGICAL DISPOSABLE"
  | "SURGICAL SUTURES"
  | "SKIN STAPLER";

export type CategorywiseMeta = {
  [key in CategoryType]: HtmlMetaType;
};
