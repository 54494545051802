import React, { useRef, useState, useEffect } from "react";
import { SingleProductType } from "../../../types/product.types";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const s3BaseUrl = "https://nuvo-medsurg.s3.eu-north-1.amazonaws.com";
export const cloudFrontBaseUrl = "https://d30p3w10xq4zf2.cloudfront.net";

export const ProductPageLeftPanel = ({ data }: { data: SingleProductType }) => {
  const [mainImg, setMainImg] = useState(data.images[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageClick = (image: string, index: number) => {
    setMainImg(image);
    setActiveIndex(index);
  };

  const addRefs = (el: HTMLDivElement | null) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  // Preload the main image
  useEffect(() => {
    const img = new Image();
    img.src = mainImg;
    img.onload = () => {
      setIsLoaded(true);
    };
  }, [mainImg]);

  return (
    <div className="w-full flex flex-col-reverse md:flex-row gap-3 items-center">
      <div className="grid grid-cols-4 md:grid-cols-1 gap-2">
        {data.images.map((image, index) => {
          return (
            <div
              key={index}
              ref={addRefs}
              className={`rounded-[4px] flex items-center justify-center w-[50px] h-[50px] cursor-pointer ${
                index === activeIndex
                  ? "border-2 border-blue-500"
                  : "border-2 border-transparent"
              }`}
              onClick={() => handleImageClick(image, index)}
            >
              <LazyLoadImage
                alt={`${data.name} - Thumbnail ${index + 1}`}
                src={image}
                className="max-w-full max-h-full object-cover"
                effect="blur"
                loading="lazy"
              />
            </div>
          );
        })}
      </div>
      <div className="max-w-[350px]">
        {isLoaded && (
          <LazyLoadImage
            alt="Cropped"
            src={mainImg}
            className="w-full h-full object-contain"
            effect="blur"
            loading="lazy"
          />
        )}
      </div>
    </div>
  );
};

export default ProductPageLeftPanel;
