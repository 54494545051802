import axios from "axios";
import baseUrl from "./baseUrl";
import { SingleProductType } from "../types/product.types";

export const getProductsByCategory = async (categoryName: string) => {
  const { data } = await axios.post(`${baseUrl}/product/category`, {
    slugName: categoryName,
  });
  return data.data as SingleProductType[];
};

export const getAllProducts = async () => {
  const { data } = await axios.get(`${baseUrl}/product`);
  return data.data as SingleProductType[];
};

export const getProductBySlug = async (productName: string) => {
  const { data } = await axios.post(`${baseUrl}/product/single`, {
    slugName: productName,
  });
  return data.data as SingleProductType[];
};
