import { LazyLoadImage } from "react-lazy-load-image-component";

export const FirstSection = () => {
  return (
    <div className="flex flex-col gap-1 md:gap-2">
      <h1 className="font-bold text-xl md:text-2xl text-center">
        Premium Quality surgical sutures manufacturer in India
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-2 md:gap-5 w-11/12 mx-auto">
        <LazyLoadImage
          src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728050952/humq16n2htvkl9p5pwnh.webp"}
          alt={"sutures"}
          effect="blur"
          loading="lazy"
          className="md:h-[500px] w-full"
        />

        <div className="mx-auto font-light text-slate-500  text-base md:text-lg lg:text-xl flex flex-col gap-2 md:gap-5">
          <p className="text-justify">
            Nuvo Medsurg is one of the most reliable companies in the medical
            sector. Nuvo Medsurg has a quality manufacturing unit in
            Bahadurgarh, Haryana, on the outskirts of Delhi. As a leading
            surgical suture manufacturer, Nuvo Medsurg ensures top-quality
            products for all your surgical needs. We believe in serving mankind
            with our best endeavour, keeping in mind the best quality and most
            affordable price.
          </p>
          <p className="text-justify">
            We have a wide range of products to satisfy the needs of the
            healthcare sector in its quest for quality, innovative products. In
            addition to our suture products, Nuvo Medsurg supplies various
            medical laboratory instruments for enhanced diagnostic capabilities.
            With our PAN India presence, we are becoming a reliable partner for
            the medical fraternity in extending their best services to human
            lives.
          </p>
        </div>
      </div>
    </div>
  );
};
