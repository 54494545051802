import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/novoLogo.svg";
import { NuvoContainer } from "../conatiner/NuvoContainer";
import { useRef } from "react";
import { useInView, motion } from "framer-motion";
import {
  EmailIcon,
  LocationIcon,
  PhoneIcon,

} from "../../assets/icons/ContactIcons";

export const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="bottom-0 !m-0 bg-nuvo-card w-full cursor-pointer pb-20">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-3  pb-5 mx-auto w-9/12"
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <motion.div
            variants={itemVariants}
            className="bg-blue-100 p-4  shadow-md text-center cursor-pointer"
            onClick={() => navigate("/categories")}
          >
            <motion.div className="flex gap-2">
              <LocationIcon className="w-10 h-10 m-auto" />
              <motion.div className="flex flex-col gap-2 m-auto">
                <p className="text-lg font-medium">Categories</p>
                <p className="text-sm">Explore our product categories</p>
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div
            variants={itemVariants}
            className="bg-blue-200 p-4  shadow-md text-center cursor-pointer"
            onClick={() =>
              (window.location.href = "mailto:info@nuvomedsurg.in")
            }
          >
            <motion.div className="flex gap-2">
              <EmailIcon className="w-10 h-10 m-auto" />
              <motion.div className="flex flex-col gap-2 m-auto">
                <p className="text-lg font-medium">Email</p>
                <p className="text-sm">Send us an Email</p>
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div
            variants={itemVariants}
            className="bg-blue-300 p-4  shadow-md text-center cursor-pointer"
            onClick={() =>
              (window.location.href = "https://wa.me/917082210770")
            }
          >
            <motion.div className="flex gap-2">
              <PhoneIcon className="w-10 h-10 m-auto" />
              <motion.div className="flex flex-col gap-2 m-auto">
                <p className="text-lg font-medium">Contact Us</p>
                <p className="text-sm">Chat with us on WhatsApp</p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        <NuvoContainer>
          <motion.div
            className="grid grid-cols-1 md:grid-cols-4 justify-between pt-20 pb-5 gap-5"
            ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
          >
            <motion.div
              variants={itemVariants}
              className="flex flex-col gap-4 text-md"
            >
              <div className="flex flex-row gap-2 items-center">
                <img
                  className="w-10 h-10 md:w-8 md:h-8"
                  src={logo}
                  alt="logo"
                />
                <p className="text-lg font-medium">Nuvo Medsurg</p>
              </div>
              <hr className="border border-black w-10/12"></hr>
              <p className=" w-11/12">
                The Nuvo Medsurg facility is an integrated manufacturing
                facility, producing finished products as well as primary
                finished packaging itself.
              </p>
            </motion.div>

            <motion.div
              variants={itemVariants}
              className="hidden md:flex flex-col gap-4 text-md"
            >
              <div className="text-lg font-medium">Additional Links</div>
              <hr className="border border-black w-10/12"></hr>
              <div className="flex flex-col gap-2 text-md">
                <p className="hover:underline" onClick={() => navigate("/")}>
                  Home
                </p>
                <p
                  className="hover:underline"
                  onClick={() => navigate("/categories")}
                >
                  Shop
                </p>
                <p
                  className="hover:underline"
                  onClick={() => navigate("/categories/surgical-sutures")}
                >
                  Surgical Sutures
                </p>
                <p
                  className="hover:underline"
                  onClick={() => navigate("/about-us")}
                >
                  About us
                </p>
              </div>
            </motion.div>
            <motion.div
              variants={itemVariants}
              className="hidden md:flex flex-col gap-4 text-md"
            >
              <div className="text-lg font-medium">Product Categories</div>
              <hr className="border border-black w-10/12"></hr>
              <div className="flex flex-col gap-2 text-md">
                {pathname !== "/" && (
                  <p
                    className="hover:underline"
                    onClick={() => navigate("/categories/surgical-sutures")}
                  >
                    Surgical Sutures
                  </p>
                )}
                <p
                  className="hover:underline"
                  onClick={() => navigate("/categories/surgical-disposable")}
                >
                  Surgical Disposable
                </p>
                <p
                  className="hover:underline"
                  onClick={() => navigate("/categories/sterile-dressings")}
                >
                  Sterile Dressings
                </p>
                <p
                  className="hover:underline"
                  onClick={() => navigate("/categories/skin-stapler")}
                >
                  Skin stapler
                </p>
              </div>
            </motion.div>
            <motion.div
              variants={itemVariants}
              className="flex flex-col gap-4 text-md"
            >
              <div className="text-lg font-medium">Get in Touch...</div>
              <hr className="border border-black w-10/12"></hr>
              <div className="w-11/12 justify-center flex flex-col gap-3 text-md">
                <div className="flex gap-2 ">
                  {/* <MapIcon className={"w-8 h-8"} /> */}
                  <p
                    className="text-justify hover:underline"
                    onClick={() =>
                      window.open(
                        "https://maps.app.goo.gl/q7UwzN2VBZxPztJi9",
                        "_blank"
                      )
                    }
                  >
                    Find Us On Google Maps
                  </p>
                </div>
                <div className="flex gap-2">
                  {/* <FooterPhoneIcon className={"w-6 h-6"} /> */}
                  <p>+91-7082210770, +91-7082210771</p>
                </div>
                <div className="flex gap-2">
                  {/* <FooterMailIcon className={"w-6 h-6"} /> */}
                  <p>info@nuvomedsurg.in</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </NuvoContainer>
      </div>
      <div className="pt-[2rem] pb-[5rem] h-14 md:h-10 bg-black flex flex-col md:flex-row md:justify-between items-center text-white text-sm px-10 gap-2">
        <p className="">© 2024 Nuvo Medsurg Pvt.ltd. All Rights Reserved.</p>
        <div className="flex flex-row gap-2">
          <p className="underline cursor-pointer" onClick={()=>navigate("/terms")}>Terms And Conditions</p>
          <p>|</p>
          <p className="underline cursor-pointer" onClick={()=>navigate("/privacy")}>Privacy Policy</p>
        </div>
      </div>
    </>
  );
};
