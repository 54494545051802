import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Navbar } from "./components/navbar/navbar";
import { CategoryPage } from "./pages/Category";
import { SingleCategoryPage } from "./pages/SingleCategory";
import { ProductView } from "./pages/ProductView";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { LoginResponse } from "./types/user.types";
import { MyCart } from "./pages/User/MyCart";
import { Footer } from "./components/Footer/Footer";
import { Homepage } from "./pages/Homepage/Homepage";
import { NuvoContext } from "./context/NuvoContext";
import Orders from "./pages/Orders";
import ErrorPage from "./pages/Error/ErrorPage";
import { AboutUs } from "./components/AboutUs";
import { ContactUs } from "./components/ContactUs";
import { CartProvider } from "./context/NuvoCartContext";


import ResetPassword from "./components/user/ResetPassword";
import ForgotPassword from "./components/user/newForgotPassword";
import ScrollTop from "./components/utils/scrollTop";
import {TermsAndConditions} from "./pages/TermsAndConditions";
import {PrivacyPolicy} from "./pages/PrivacyPolicy";

const App: React.FC = () => {
  const { pathname } = useLocation();
  const hideNavAndFooterRoutes = ["/signup", "/login"];
  const [render, setRender] = useState<boolean>(false);
  const [user, setUser] = useState<LoginResponse | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const showRoutes = !hideNavAndFooterRoutes.includes(pathname);
  const navigate = useNavigate();
  useEffect(() => {
    const storedUser = localStorage.getItem("nuvo_user_cred");
    const user: LoginResponse = storedUser ? JSON.parse(storedUser) : null;

    if (user?.user) {
      setUser(user);
    }
  }, [render]);

  const handleError = useCallback(
    (error: unknown) => {
      console.error("An error occurred:", error);
      if (!hasError) {
        setHasError(true);
        navigate("/error");
      }
    },
    [hasError, navigate]
  );

  const contextValue = useMemo(
    () => ({
      render,
      setRender,
      user,
      setUser,
      handleError,
    }),
    [render, user, handleError]
  );

  const homepageMarginTop =
    pathname === "/"
      ? "mt-[70px]"
      : pathname === "/about-us"
      ? ""
      : "mt-[130px]";

  return (
    <Suspense fallback={<div>Loading....</div>}>
    <NuvoContext.Provider value={contextValue}>
      <CartProvider>
        <ScrollTop />
        <div className="app-container font-light scrollbar-hide flex flex-col min-h-screen overflow-x-hidden">
          {showRoutes && (
            <Navbar user={user} setRender={setRender} setUser={setUser} />
          )}
          <div className={`flex-grow ${homepageMarginTop}`}>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/mycart" element={<MyCart />} />
              <Route path="/categories" element={<CategoryPage />} />
              <Route
                path="/categories/:categoryName"
                element={<SingleCategoryPage />}
              />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route
                path="/categories/:categoryName/:productName"
                element={<ProductView />}
              />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
          </div>
          {showRoutes && <Footer />}
        </div>
      </CartProvider>
    </NuvoContext.Provider>
    </Suspense>
  );
};

export default App;
