export const TermsAndConditions = () => {
  return (
    <div className="flex flex-col gap-10 p-10 mt-7 mb-20 ">
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">Terms & Condtions</div>
        <p>
          PLEASE READ THE FOLLOWING TERMS CAREFULLY BEFORE USING THIS WEBSITE
        </p>
        <p>
          The operator of this website, nuvomedsurg (“we” or “us”), offers
          access to the website at https://www.nuvomedsurg.com/ (the “Site”),
          conditioned on your acceptance without modification of the terms,
          conditions, and notices contained herein (collectively, “Terms”) and
          the Privacy Policy displayed on the Site. In addition, particular
          features, applications, and activities offered as part of the Site may
          also be subject to additional terms specified in connection with such
          features, applications, and activities, all incorporated herein by
          reference
        </p>
      </div>
      <p className="font-semibold">
        BY ACCESSING OR USING THE SITE, YOU AGREE TO THESE TERMS AND THE PRIVACY
        POLICY. IF YOU DO NOT AGREE TO THESE TERMS AND THE PRIVACY POLICY, DO
        NOT USE THE SITE.
      </p>
      <p>
        Please review the Applicable Laws and Disputes section of these Terms
        for complete details.
      </p>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">MODIFICATION OF THESE TERMS</div>
        <p>
          From time to time, we may change these Terms. The “Last Updated” line
          above will be revised when we do. You are responsible for regularly
          reviewing these Terms. Your continued use of the Site after posting
          changes to the Terms indicates your acceptance of those changes.
          Unless we provide you with specific notice, no changes to the Terms
          will apply retroactively.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">ELIGIBILITY</div>
        <p>
          The Site is not intended for use by people younger than age 13. Do not
          use the Site if you are under 13.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">PERSONAL USE OF THE SITE</div>
        <p>
          The Site is for your personal, non-commercial use only. You shall not
          sell or commercially exploit in any manner the Site, access to the
          Site, or any of the content featured or displayed on the Site
          (“Content”). You shall not modify, distribute, transmit, display,
          perform, reproduce, publish, create derivative works from, or transfer
          any Content without our express written permission. Unauthorized use
          of any Content could cause irreparable harm to us and may result in
          legal action.
        </p>
        <p>
          We may suspend or terminate your account or your use of the Site at
          any time, for any reason or no reason, including if you violate these
          Terms. Unauthorized use of automated systems to access the Site is
          prohibited.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">USER SUBMISSIONS</div>
        <p>
          Any comments, materials, information, or other communications you
          post, upload, or submit to the Site (each a “Communication”) will be
          considered non-confidential and non-proprietary. You are responsible
          for ensuring that your Communications do not infringe upon any
          copyrights, trademarks, or other rights of third parties
        </p>
        <p>
          By posting a Communication, you grant us a non-exclusive, perpetual,
          irrevocable, royalty-free, fully paid-up worldwide, fully
          sublicensable right to use and distribute the Communication, without
          further notice or payment to you.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          LIABILITY DISCLAIMER FOR EDITORIAL CONTENT
        </div>
        <p>
          We are not responsible for any third-party products or services
          mentioned on the Site and do not guarantee the accuracy or reliability
          of any Content. The Site may include information related to medical,
          legal, financial, or health topics, but we do not provide professional
          advice. Always consult a professional for specific advice
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">DISCLAIMER</div>
        <p>
          The Site is provided "AS IS" without warranties of any kind, whether
          express or implied. We disclaim all warranties, including, but not
          limited to, implied warranties of merchantability and fitness for a
          particular purpose. We do not guarantee the Site will be error-free or
          that defects will be corrected
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">LIMITATION OF LIABILITY</div>
        <p>
          In no event will we be liable for any direct, indirect, special,
          punitive, incidental, exemplary, or consequential damages arising out
          of the use or inability to use the Site. Some jurisdictions do not
          allow the exclusion or limitation of liability for consequential or
          incidental damages, so the above limitation may not apply to you
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">INDEMNIFICATION</div>
        <p>
          You agree to indemnify, defend, and hold us harmless from any claims,
          damages, or costs resulting from your use of the Site or violation of
          these Terms
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">APPLICABLE LAW AND DISPUTES</div>
        <p>
          These Terms are governed by the laws of India. Any disputes arising
          out of or related to the Terms or the Site will be resolved
          individually and without class action. You must commence any claim
          within one year of the claim arising
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">INTELLECTUAL PROPERTY</div>
        <p>
          The Site is the property of nuvomedsurg. Content owned by others and
          licensed to us is also protected. All rights reserved.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">
          NOTICES AND PROCEDURES FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
        </div>
        <p>
          If you believe your work has been infringed, please provide the
          following information as specified by the Digital Millennium Copyright
          Act (DMCA):
        </p>
        <ul className="list-disc ml-6">
          <li>A signature of the copyright owner or authorized agent;</li>
          <li>A description of the copyrighted work;</li>
          <li>Location of the infringing material;</li>
          <li>Your contact information;</li>
          <li>
            A statement of good faith belief that the use is unauthorized;
          </li>
          <li>A statement of accuracy under penalty of perjury;</li>
        </ul>
        <p>Notices should be directed to:</p>
        <p>By email: info@nuvomedsurg.in</p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">CODE OF CONDUCT</div>
        <p>
          You shall not post any harmful, threatening, or objectionable material
          on the Site or infringe upon the rights of others.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">THIRD-PARTY SITES</div>
        <p>
          The Site may contain links to third-party websites, which have their
          own privacy and data collection practices. We are not responsible for
          the content or privacy practices of these third-party sites.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="text-2xl lg:text-3xl font-bold">THIRD-PARTY SITES</div>
        <p>
          The Site may contain links to third-party websites, which have their
          own privacy and data collection practices. We are not responsible for
          the content or privacy practices of these third-party sites.
        </p>
      </div>
    </div>
  );
};
