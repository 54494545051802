import { LazyLoadImage } from "react-lazy-load-image-component";


export const AboutUs = () => {
  const data = [
    "Surgical Sutures & Ligatures.",
    "Surgical Disposables: Nitrile Examination Gloves, Latex Examination Gloves, N-95 Mask, 3 Ply Face Mask, Surgeon Cap, Bouffant Cap,&nbsp; Shoe Cover etc.",
    "COVID 19 Range: PPE Kit, Gloves Etc.",
    "Body Cleaning Wipes: Baby Wipes, Bed Bath Towel etc.",
    "Surgical Dressings: Gauze Dressings (Sterile & Non Sterile), Abdominal Sponge, Elastic Adhesive Bandage,  Microporous Tape,  Haemostatic Gelatine Sponge Etc.",
    "CSSD Consumables: Sterilization reels, Chemical Indicators etc.",
    "Medical Devices: Urine Collection Bag, Urometer",
  ];

  const manufacturingData = [
    "Nuvo Medsurg has developed its infrastructure to manufacture high quality products. For manufacturing sutures we have designed ISO Class-7 clean rooms for non absorbable sutures ( it meets class 10000 cleanliness standards approved as per international requirements) . We have also designed and developed ISO-6 clean-rooms for its absorbable sutures.",
    "Our infrastructure is developed as per international regulatory agencies. Our plant ensures non polluted and non contaminated environment for quality production. The process ensures less toxins before sterilization and gives desired quality product post sterilization. ",
  ];

  const qmsData = [
    "The Quality Management System (QMS) is developed to provide assurance that all systems are designed and implemented as per the standard norms. ",
    "The Quality Management System is reviewed periodically to keep it updated with any changes in practices. This will be driven by Quality Assurance and reviewed by the management periodically. ",
  ];

  const qmsList = [
    "Strict and clear Quality Policy",
    "Continuous Quality Risk Management",
    "Latest and Robust Technology ",
    "Manufacturing Facility Designed as per international standards ",
    "Strong Adherence to Standard Operating Procedures ",
    "Implementation of Compliance to maintain standard of quality",
    "Regular Internal Audit System for Operating Procedures",
    "Strong follow up of role & responsibility of Quality Assurance Function.",
  ];
  return (
    <div className="mt-[20rem] md:mt-0">
      <LazyLoadImage
        className="-mt-[200px] w-full"
        src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053308/qwo39ou3bgckgzs57ruz.webp"}
        alt="AboutUsImage"
         loading="lazy"
            effect="blur"
      />
      <div className="w-10/12 mx-auto flex flex-col gap-10 my-10">
        <div className="flex flex-col gap-5">
          <p className="text-lg md:text-xl lg:text-2xl text-justify">
            Nuvo Medsurg has its state of art manufacturing plant at
            Bahadurgarh, Haryana. This plant is equipped with world class
            manufacturing facilities. Nuvo Medsurg is guided by experts and its
            plant is driven by highly skilled manufacturing team. We at Nuvo
            Medsurg leave no efforts to provide best quality products from our
            most advanced plant. We manufacture wide range of products to suit
            the need of healthcare sector. Our most liked products segment has
            following products:
          </p>
          <ul className="text-justify list-disc ml-10 text-slate-500 text-xl">
            {data.map((d) => (
              <li className="text-sm lg:text-base">{d}</li>
            ))}
          </ul>
        </div>
        <hr className="border border-black w-full mx-auto"></hr>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053478/grg1ngdgsees8ucypy7q.webp"}
            alt={"manufacturingImage"}
            loading="lazy"
            effect="blur"
            className="max-h-[400px] w-full"
          />
          <div className="flex flex-col gap-5">
            <div className="w-fit">
              <p className="text-lg md:text-xl lg:text-2xl">Manufacturing Unit</p>
              <hr className="border border-black"></hr>
            </div>
            {manufacturingData.map((m) => (
              <p className="text-justify text-slate-500 text-sm md:text-base lg:text-xl">{m}</p>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="flex flex-col gap-5">
            <div className="w-fit">
              <p className="text-lg md:text-xl lg:text-2xl">Quality management system</p>
              <hr className="border border-black"></hr>
            </div>
            <p>{qmsData[0]}</p>
            <ul className="text-justify list-disc font-normal text-base md:text-lg lg:text-xl ml-5">
              {qmsList.map((qms) => (
                <li className="text-slate-500  text-sm lg:text-base">{qms}</li>
              ))}
            </ul>
            <p className="text-base md:text-lg lg:text-xl">{qmsData[1]}</p>
          </div>
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053310/h9tmc5n9ootngtuxo9uu.webp"}
            alt={"QmsImage"}
            loading="lazy"
            effect="blur"
            className="max-h-[400px] w-full"
          />
        </div>
      </div>
    </div>
  );
};
