import { useEffect, useState } from "react";
import { ShopByCategorySection } from "../../components/homepage/ShopByCategorySection";
import { SurturesSection } from "../../components/homepage/SurturesSection";
import { CategoryDataType } from "../../types/category.types";
import { getAllCategories } from "../../handlers/categoryHandler";
import { FeaturedCategories } from "../../components/homepage/FeaturedCategories";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNuvoContext } from "../../context/NuvoContext";
import { CategoriesNew } from "../../components/homepage/CategoriesNew";
import { WhyUs } from "../../components/homepage/WhyUs";
import Experience from "../../components/homepage/Experience";
import Testimonials from "../../components/homepage/Testimonials";
import ImageCarousel from "../../components/ImageCarousel";
import { Manufacturer } from "../../components/homepage/Manufacturer";
import { FirstSection } from "../../components/homepage/FirstSection";
import { WideRange } from "../../components/homepage/WideRange";
import { HomeCard } from "../../components/homepage/HomeCard";
import { IdealSuture } from "../../components/homepage/IdealSuture";

export const Homepage = () => {
  const [categoryData, setCategoryData] = useState<CategoryDataType | null>(
    null
  );
  const { handleError } = useNuvoContext();
 
  const getAllCategoriesData = async () => {
   
    try {
      const data = await getAllCategories();
      setCategoryData(data);
    } catch (error: unknown) {
      handleError(error);
    } 
  };

  useEffect(() => {
    getAllCategoriesData();
    //eslint-disable-next-line
  }, []);

  const { isMd } = useWindowWidth();
  return (
    <div className="w-full pb-10">
      <ImageCarousel />
      <>
        <div className="w-full py-10 flex flex-col gap-10">
          <FirstSection />
          <SurturesSection />
          <hr className="border border-black w-11/12 mx-auto my-10"></hr>
          {categoryData && <CategoriesNew data={categoryData} />}
          <hr className="border border-black w-11/12 mx-auto my-10"></hr>
          <WideRange />
          <Experience />
          <WhyUs />
          <Manufacturer />
          {!isMd && categoryData && (
            <ShopByCategorySection data={categoryData} />
          )}
          <HomeCard />
          {isMd && categoryData && <FeaturedCategories data={categoryData} />}
          <IdealSuture />
          <Testimonials />
        </div>
      </>
    </div>
  );
};
