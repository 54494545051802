import React, { useEffect, useState } from "react";
import { MyOrderType } from "../../types/orders.types";
import { NuvoContainer } from "../conatiner/NuvoContainer";
import { getLocalStorage } from "../../helpers/authHelper";
import { LoginResponse, UserDetailsType } from "../../types/user.types";
import { useNuvoContext } from "../../context/NuvoContext";
import { getMyDetails } from "../../handlers/userHandler";
import { Link } from "react-router-dom";

interface AccordionProps {
  order: MyOrderType;
}

const Accordion: React.FC<AccordionProps> = ({ order }) => {
  const [userData, setUserData] = useState<UserDetailsType | null>(null);
  // @ts-ignore
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleError } = useNuvoContext();

  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse = getLocalStorage("nuvo_user_cred");
      const data = await getMyDetails(user.token);
      setUserData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const calculateEstimatedDelivery = (orderDate: string) => {
    const date = new Date(orderDate);
    date.setDate(date.getDate() + 3);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <NuvoContainer>
      <div className="border-2 border-black text-black w-full md:w-11/12 lg:w-9/12 rounded-lg mt-7 flex flex-col justify-center">
        <div className="flex flex-col sm:flex-row py-4 px-4 sm:px-6 gap-4 sm:gap-10 justify-between bg-gray-100 rounded-t-lg">
          <div className="flex flex-row sm:flex-col gap-2 justify-between sm:justify-start">
            <p className="font-semibold sm:font-normal">Order Placed</p>
            <p>{new Date(order.date).toISOString().slice(0, 10)}</p>
          </div>
          <div className="flex flex-row sm:flex-col gap-2 justify-between sm:justify-start">
            <p className="font-semibold sm:font-normal">Order Total</p>
            <p className="font-normal sm:font-semibold">
              ₹{order.payment.amount / 100}
            </p>
          </div>
          <div className="flex flex-row sm:flex-col gap-2 justify-between sm:justify-start">
            <p className="font-semibold sm:font-normal">Ship to</p>
            <p>
              {userData?.user?.name
                ? userData.user.name.length > 10
                  ? userData.user.name.slice(0, 10) + "..."
                  : userData.user.name
                : ""}
            </p>
          </div>
          <p className="text-right sm:text-left">
            Order # NUVO_0000{order.orderId}
          </p>
        </div>

        <div className="w-full">
          {order.cart.products.map((product, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-start sm:items-center gap-4 p-4 w-full border-b last:border-b-0"
            >
              <div className="w-full sm:w-[100px] h-[100px] md:w-[150px] md:h-[150px] flex-shrink-0">
                <img
                  src={product.product.image}
                  alt="ProductImage"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <Link
                  to={`/categories/${product.product.category.slug}/${product.product.slug}`}
                  className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
                >
                  <p className="text-lg font-semibold">
                    {product.product.name}
                  </p>
                </Link>
                <div className="flex flex-row gap-3">
                  <p className="text-sm text-gray-600 mb-2 sm:mb-0">
                    Size: {product.size}
                  </p>
                  <p className="text-sm text-gray-600 mb-2 sm:mb-0">
                    Quantity: {product.quantity}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-2 sm:mt-0">
                  <p className="text-sm text-gray-600 mb-2 sm:mb-0">
                    Estimated Delivery: {calculateEstimatedDelivery(order.date)}
                  </p>
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium">Order Status:</p>
                    <p
                      className={`text-sm font-semibold rounded-full py-1 px-3 ${
                        order.orderStatus === "ORDERED"
                          ? "bg-yellow-200 text-yellow-800"
                          : order.orderStatus === "SHIPPED"
                          ? "bg-blue-200 text-blue-800"
                          : order.orderStatus === "DELIVERED"
                          ? "bg-green-200 text-green-800"
                          : "bg-gray-200 text-gray-800"
                      }`}
                    >
                      {order.orderStatus}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </NuvoContainer>
  );
};

export default Accordion;
