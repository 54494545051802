import { useState } from "react";
import { NuvoEditIcon } from "../../assets/icons/EditIcon";
import { UserAddress } from "../../types/user.types";
import { MyProfileFlyIn } from "../loggedUser/ProfileFlyIn";

export const UserAddressView = ({ user }: { user: UserAddress }) => {
  const [isMyProfileOpen, setIsMyProfileOpen] = useState<boolean>(false);

  return (
    <div className="border border-black rounded-[8px] flex p-2">
      <div className="flex flex-col gap-2 p-2 ">
        <p className="text-xs md:text-body-1 font-medium">
          Delivery Address :{" "}
        </p>
        <p className="text-xs md:text-body-1">
          {user.doorNo},{user.streetName} , {user.city} , {user.state} ,{" "}
          {user.pincode}
        </p>
      </div>
      <div onClick={() => setIsMyProfileOpen(true)} className="cursor-pointer">
        <NuvoEditIcon />
      </div>
      {isMyProfileOpen && (
        <MyProfileFlyIn
          isOpen={isMyProfileOpen}
          setIsOpen={setIsMyProfileOpen}
        />
      )}
    </div>
  );
};
