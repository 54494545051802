import clock from "../assets/images/clock.svg";
import desktop from "../assets/images/desktop.svg";
import map from "../assets/images/map.svg";
import users from "../assets/images/users.svg";
export const experienceServices = [
  {
    image: clock,
    content: "11",
    content2: "Years",
  },
  {
    image: map,
    content: "15",
    content2: " countries",
  },
  {
    image: users,
    content: "50",
    content2: "Employees",
  },
  {
    image: desktop,
    content: "250",
    content2: " Distributors",
  },
];
