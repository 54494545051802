import {
  divClassName,
  h1ClassName,

} from "../../single-category-css";

export const SkinStaplerSectionOne = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      <h1 className={h1ClassName}>Buy skin stapler online:</h1>
      <div className={divClassName}>
        <p>
          Are you looking for a reliable skin stapler? Nuvo Medsurg provides
          high-quality skin staplers intended for precision and ease of usage.
          Our skin staplers are perfect for wound closure and provide quick and
          effective outcomes in both surgical and therapeutic settings. You
          obtain high-quality items that satisfy stringent safety regulations by
          buying directly from reputable manufacturers. Our web platform makes
          obtaining the skin stapler you require simple, ensuring you have
          access to the best instruments for patient treatment. Choose Nuvo
          Medsurg for dependable, professional-grade skin staplers that
          healthcare professionals trust.
        </p>
      </div>
    </div>
  );
};
