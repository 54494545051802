import axios, { AxiosResponse } from "axios";
import baseUrl from "./baseUrl";

export default async function tokenVerify(token: string): Promise<AxiosResponse<any>> {
  try {
    const response = await axios.post(
      `${baseUrl}/verifyToken`,
      {}, // The body can be empty or include other data if needed
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.debug("Error in token verification:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}
