import React, { useEffect, Suspense, lazy } from "react";
import { CategoryDataType } from "../../types/category.types";
import Shimmer from "../Shimmer/Shimmer";

const LazyLoadImage = lazy(() =>
  import("react-lazy-load-image-component").then((module) => ({
    default: module.LazyLoadImage,
  }))
);

export const CategoriesNew = ({ data }: { data: CategoryDataType }) => {
  const filteredData = data
    .filter((d) => ["SURGICAL SUTURES", "SKIN STAPLER"].includes(d.name))
    .sort((a, b) => {
      const order = ["SURGICAL SUTURES", "SKIN STAPLER"];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });

  const staplerApplications = [
    "For Skin closure in Operating Room",
    "Emergency Department",
    "Labor and Delivery",
    "Outpatient Surgery",
    "Clinics and Physician’s Oﬃce",
  ];

  return (
    <div className="w-11/12 mx-auto flex flex-col gap-5 mt-[2px] md:mt-[50px]">
      {/* <hr className="border border-black w-full mx-auto my-10"></hr> */}
      <p className="text-xl md:text-2xl">Our Flagship Products : </p>
      <div className="mb-10 md:mb-20 flex flex-col gap-20">
        {filteredData.map((fd) => (
          <div
            key={fd.name}
            className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center"
          >
            <Suspense
              fallback={
                <div
                  key={fd.name}
                  className="cursor-pointer bg-nuvo-card shadow-md min-w-[350px] md:min-w-[450px] flex-1 flex p-6 flex-col items-center rounded-[8px]"
                >
                  <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
                </div>
              }
            >
              <LazyLoadImage
                src={fd.image}
                alt={fd.name}
                className="w-full h-full object-contain"
                effect="blur"
                loading="lazy"
              />
            </Suspense>
            {fd.name === "SURGICAL SUTURES" && (
              <div className="flex flex-col gap-5">
                <p className="text-justify text-lg md:text-2xl font-medium">
                  <strong>Nuvo Sutures</strong> is the brand name of surgical
                  Sutures manufactured by Nuvo Medsurg.
                </p>
                <div className="text-justify text-slate-500 text-base md:text-xl flex flex-col gap-5">
                  <p>
                    Surgical sutures are vital healthcare devices used for
                    closing wounds and incisions and also promoting healing.
                    These sutures come in a variety of materials and are
                    designed to bear the severe requirements of surgical
                    procedures in order to promote healthy and fast wound
                    healing.
                  </p>
                  <p>
                    Surgical sutures are known for being versatile,
                    biocompatible, and strong. Sutures are made to be
                    biocompatible to prevent uncomfortable responses; they are
                    made to be flexible to move with the body's natural motions
                    and strong enough to keep tissue together under any stress.
                  </p>
                  <p>
                    Surgical sutures play an important role in healthcare,
                    ensuring the success of the operations and patients'
                    recovery. The main functions of surgical sutures comprise
                    healing the wounds, promoting tissue recovery, controlling
                    bleeding (hemostasis), and minimizing scars. Surgical
                    sutures are necessary for medical treatment efficacy and
                    patient security.
                  </p>
                </div>
              </div>
            )}
            {fd.name === "SKIN STAPLER" && (
              <div className="text-justify flex flex-col gap-5">
                <p className="text-lg md:text-2xl font-medium">
                  <strong> NUVOFIX Staple Remover</strong> are specially
                  designed and produced for the good function to remove the
                  staples easily after the skin was sutured by our Disposable
                  skin stapler.
                </p>
                <div className="text-slate-500 text-base md:text-xl flex flex-col gap-5">
                  <p>
                    NUVOFIXTM Skin Stapler, also known as wound stapler or
                    surgical stapler are medical tools for a variety of skin
                    closures. The staples ﬁrst penetrate the skin and are then
                    formed, holding the tissue together.
                  </p>
                  <ul className="text-justify ml-5 text-base md:text-lg list-disc">
                    {staplerApplications.map((sa) => (
                      <li>{sa}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
