/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SingleProductType } from "../../types/product.types";
import { getProductsByCategory } from "../../handlers/productHandler";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNuvoContext } from "../../context/NuvoContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Shimmer from "../Shimmer/Shimmer";

export const SurturesSection = () => {
  const [productsData, setProductsData] = useState<SingleProductType[] | null>(
    null
  );
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { handleError } = useNuvoContext();
  const getAllProducts = async (categoryName: string) => {
    setIsloading(true);
    try {
      const data = await getProductsByCategory(categoryName);
      setProductsData(data);
      setIsloading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllProducts("surgical-sutures");
  }, []);

  const navigate = useNavigate();

  const { isLg } = useWindowWidth();
  const iterateUpto =
    productsData && productsData.length
      ? isLg
        ? productsData.length
        : Math.ceil(productsData.length / 2)
      : 0;

  return (
    <div className="w-11/12 mx-auto flex flex-col gap-4">
      <p className="text-title-7 md:text-title-5 font-medium">
        SURGICAL SUTURES
      </p>
      {isLoading ? (
        <div className="w-full overflow-x-auto scrollbar-hide flex flex-row gap-4">
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="cursor-pointer bg-nuvo-card shadow-md min-w-[350px] md:min-w-[450px] flex-1 flex p-6 flex-col items-center rounded-[8px]"
              >
                <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
              </div>
            ))}
        </div>
      ) : (
        <div className="w-full overflow-x-auto scrollbar-hide flex flex-row gap-4">
          {productsData &&
            productsData.slice(0, iterateUpto).map((product) => (
              <div
                key={product.slug}
                onClick={() => {
                  navigate(`/categories/surgical-sutures/${product.slug}`);
                }}
                className="cursor-pointer bg-nuvo-card shadow-md min-w-[300px] md:min-w-[400px] flex-1 flex p-4 flex-col items-center rounded-[8px]"
              >
                <div className="flex-shrink-0 h-[250px] md:h-[300px] flex justify-center items-center">
                  <LazyLoadImage
                    className="w-full h-full"
                    src={product.images[0]}
                    alt={product.name}
                    effect="blur"
                    loading="lazy"
                  />
                </div>
                <p className="font-medium text-sm md:text-base text-title-6">
                  {product.name}
                </p>
                <p className="w-full text-sm md:text-base text-center truncate font-medium ">
                  {product.genericName}
                </p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
