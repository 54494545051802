import { useEffect } from "react";
import {
  ProductDescriptionType,
  SingleProductType,
} from "../../types/product.types";
import {
  cloudFrontBaseUrl,
  s3BaseUrl,
} from "./product-sections/ProductPageLeftPanel";
import NuvoTable from "../shared/table/NuvoTable";

export const ProductDetailsComponent = ({
  productData,
}: {
  productData: SingleProductType;
}) => {
  const metaData = productData.metaData;

  const tensileColumns = metaData?.tensileStrength?.every((ts) => ts.subcat)
    ? [
        { header: "Post Implantation", accessor: "postIm" },
        { header: "Tensile Strength", accessor: "strength" },
        { header: "Sub category", accessor: "subCat" },
      ]
    : [
        { header: "Post Implantation", accessor: "postIm" },
        { header: "Tensile Strength", accessor: "strength" },
      ];

  const formattedTensileData =
    metaData.tensileStrength?.map((item) => ({
      postIm: item.Post_Implantation + " days",
      ...(item.strength && {
        strength: item.strength + " %",
      }),
      ...(item.subcat && {
        subCat: item.subcat
          .map((sub) => `${sub.name}: ${sub.value}`)
          .join(", "),
      }),
    })) || [];

  const productDescColumns = [
    { header: "Key", accessor: "key" },
    { header: "Value", accessor: "value" },
  ];

  const flattenedProductDescData =
    metaData.product_Desc?.map((descItem) => {
      const key = Object.keys(descItem)[0];
      const value = descItem[key as keyof ProductDescriptionType];
      return { key, value };
    }) || [];

  const columns = [
    { header: "", accessor: "id" },
    { header: "", accessor: "value" },
  ];

  const data = Object.entries(productData.metaData).map(([key, value]) => ({
    id: key,
    value: value,
  }));
  const isNuvomono = productData.name === "Nuvomono";

  return (
    <div className="flex flex-col gap-8">
      <p className="text-title-5 md:text-title-4 font-bold">
        Product Description :{" "}
      </p>
      {productData.category.name === "SKIN STAPLER" && (
        <div className="flex flex-col gap-5 md:gap-20">
          <div className="grid grid-cols-2 gap-5">
            {productData.metaData.benefits && (
              <div className="flex flex-col gap-5">
                <p className="text-lg md:text-xl lg:text-2xl font-medium">
                  Benefits :
                </p>
                <ul className="text-justify ml-5 list-disc text-xl flex flex-col gap-4 text-slate-500">
                  {productData.metaData.benefits.map((f) => (
                    <li className="text-sm md:text-base">{f}</li>
                  ))}
                </ul>
              </div>
            )}
            <img
              alt="Cropped"
              src={productData.images[0]}
              className="w-full h-full object-contain"
              loading="lazy"
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <img
              alt="Cropped"
              src={productData.images[0]}
              className="w-full h-full object-contain"
              loading="lazy"
            />
            {productData.metaData.applications && (
              <div className="flex flex-col gap-5">
                <p className="text-lg md:text-xl lg:text-2xl font-medium">
                  Applications :
                </p>
                <ul className="text-justify ml-5 list-disc text-xl flex flex-col gap-4 text-slate-500">
                  {productData.metaData.applications.map((f) => (
                    <li className="text-sm md:text-base">{f}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {productData.metaData.features && (
              <div className="flex flex-col gap-5">
                <p className="text-lg md:text-xl lg:text-2xl font-medium">
                  Features :
                </p>
                <ul className="text-justify ml-5 list-disc text-xl flex flex-col gap-4 text-slate-500">
                  {productData.metaData.features.map((f) => (
                    <li className="text-sm md:text-base">{f}</li>
                  ))}
                </ul>
              </div>
            )}
            {productData.metaData.advantages && (
              <div className="flex flex-col gap-5">
                <p className="text-lg md:text-xl lg:text-2xl font-medium">
                  Advantages :
                </p>
                <ul className="text-justify ml-5 list-disc text-xl flex flex-col gap-4 text-slate-500">
                  {Array.isArray(productData.metaData.advantages) ? (
                    productData.metaData.advantages.map((f) => (
                      <li className="text-sm md:text-base">{f}</li>
                    ))
                  ) : (
                    <li className="text-sm md:text-base">
                      {productData.metaData.advantages}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
      {productData.category.name === "SURGICAL SUTURES" && (
        <div className={`grid w-full lg:grid-cols-12 gap-5 `}>
          {productData.metaData.product_Desc && (
            <div
              className={`${isNuvomono ? "lg:col-span-12" : "lg:col-span-8"}`}
            >
              <NuvoTable
                columns={productDescColumns}
                data={flattenedProductDescData}
              />
            </div>
          )}

          {productData.metaData.tensileStrength && !isNuvomono && (
            <div className="lg:col-span-4">
              <NuvoTable
                showHeader={true}
                columns={tensileColumns}
                data={formattedTensileData}
              />
            </div>
          )}
        </div>
      )}
      {productData.category.name !== "SURGICAL SUTURES" &&
        productData.category.name !== "SKIN STAPLER" && (
          <div className="w-full">
            <NuvoTable columns={columns} data={data} />
          </div>
        )}
    </div>
  );
};
