import { Toaster } from "react-hot-toast";

export const ToastProvider = () => {
  return (
    <div>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "4px",
            fontSize: "18px",
            padding: "10px",
            backgroundColor: "bg-danger",
          },
          duration: 1000,
          error: {
            duration: 1500,
            className: "bg-red",
          },
        }}
      />
    </div>
  );
};
