/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getLocalStorage } from "../../helpers/authHelper";
import { CartRightPanel } from "./panels/RightPanel";
import { CartLeftPanel } from "./panels/LeftPanel";
import { NuvoContainer } from "../conatiner/NuvoContainer";
import { SingleCartItemType } from "../../types/cart.types";

import { Formik, Form } from "formik";
import Shimmer from "../Shimmer/Shimmer";

export type CartInitialValuesType = {
  products: SingleCartItemType[];
};
export const CartView = () => {
  const [data, setData] = useState<SingleCartItemType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshCart, setRefreshCart] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      const cartData: SingleCartItemType[] = getLocalStorage("nuvo_cart");
      setData(cartData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [refreshCart]);

  if (isLoading) {
    return (
      <NuvoContainer>
        <div className="w-full flex flex-col md:flex-row gap-5 py-10">
          {/* Left Side Shimmer */}
          <div className="cursor-pointer bg-nuvo-card w-8/12 h-[250px]  p-4 flex flex-col rounded-[8px]">
            <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
          </div>

          {/* Right Side Shimmer Container */}
          <div className="flex flex-col gap-2 w-4/12">
            {/* Top Right Shimmer */}
            <div className="cursor-pointer bg-nuvo-card min-h-fit p-4 flex flex-col rounded-[8px] h-[200px]">
              <Shimmer w="100%" h={100} radius="4px 4px 0 0" />
            </div>

            {/* Bottom Right Shimmer */}
            <div className="cursor-pointer bg-nuvo-card min-h-fit p-4 flex flex-col rounded-[8px] h-[300px]">
              <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
            </div>
          </div>
        </div>
      </NuvoContainer>
    );
  } else if (!data || data?.length <= 0) {
    return (
      <NuvoContainer>
        <div className="w-full flex flex-col md:flex-row gap-5 py-10">
          {/* Left Side Shimmer */}
          <div className="cursor-pointer bg-nuvo-card w-8/12 h-[250px]  p-4 flex flex-col rounded-[8px]">
            <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
          </div>

          {/* Right Side Shimmer Container */}
          <div className="flex flex-col gap-2 w-4/12">
            {/* Top Right Shimmer */}
            <div className="cursor-pointer bg-nuvo-card min-h-fit p-4 flex flex-col rounded-[8px] h-[200px]">
              <Shimmer w="100%" h={100} radius="4px 4px 0 0" />
            </div>

            {/* Bottom Right Shimmer */}
            <div className="cursor-pointer bg-nuvo-card min-h-fit p-4 flex flex-col rounded-[8px] h-[300px]">
              <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
            </div>
          </div>
        </div>
      </NuvoContainer>
    );
  }

  return (
    <NuvoContainer>
      {!isLoading && data && (
        <CartFormView
          data={data}
          setData={setData}
          setRefreshCart={setRefreshCart}
          setIsLoading={setIsLoading}
        />
      )}
    </NuvoContainer>
  );
};

const CartFormView = ({
  data,
  setData,
  setRefreshCart,
  setIsLoading,
}: {
  data: SingleCartItemType[];
  setData: Dispatch<SetStateAction<SingleCartItemType[]>>;
  setRefreshCart: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Formik
      initialValues={{ products: data }}
      onSubmit={() => {}}
      validateOnChange
    >
      <Form className="w-full flex flex-col-reverse lg:grid lg:grid-cols-12 gap-5 py-10">
        <div className="md:col-span-8">
          <CartLeftPanel
            setRefreshCart={setRefreshCart}
            setData={setData}
            setIsLoading={setIsLoading}
          />
        </div>
        <div className="md:col-span-4">
          <CartRightPanel data={data} />
        </div>
      </Form>
    </Formik>
  );
};
