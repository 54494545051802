import emailjs from "@emailjs/browser";
import { CartProductType, MyCartType } from "../types/cart.types";
import { LoginResponse } from "../types/user.types";

export const sendConfirmationEmail = async (
  orderDetails: {
    razorpay_order_id: string;
  },
  cartData: MyCartType,
  loginResponse: LoginResponse | null,
  cartTotal: number
) => {
  try {
    const user = loginResponse?.user;
    const productNames = cartData.products
      .map((p: CartProductType) => p.product.name)
      .join(", ");
    const totalQuantity = cartData.products.reduce(
      (total: number, product: CartProductType) => total + product.quantity,
      0
    );

    // Create a form element
    const form = document.createElement("form");

    // Add input fields to the form
    const fields = {
      to_name: user?.name || "Valued Customer",
      product_name: productNames,
      order_number: orderDetails.razorpay_order_id,
      quantity: totalQuantity.toString(),
      total_price: `₹${cartTotal}`,
      support_email: "support@novomedsurg.com",
      from_name: "Novo Medsurg",
      user_email: user?.email || "",
    };

    Object.entries(fields).forEach(([name, value]) => {
      const input = document.createElement("input");
      input.type = "text";
      input.name = name;
      input.value = value;
      form.appendChild(input);
    });

    // Send the email
    const result = await emailjs.sendForm(
      "success-email",
      "template_k3ufluu",
      form,
      "FzdQXOAVFVBfwQvvE"
    );

    console.log("Confirmation email sent successfully", result.text);
  } catch (error) {
    console.error("Error sending confirmation email:", error);
  }
};
