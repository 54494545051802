import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NuvoDrawer } from "../nuvo-drawer/NuvoDrawer";
import { LoginResponse, UserDetailsType } from "../../types/user.types";
import { getLocalStorage } from "../../helpers/authHelper";
import * as Yup from "yup";
import { editUserHandler, getMyDetails } from "../../handlers/userHandler";
import toast from "react-hot-toast";
import { Loader } from "../shared/Loader/Loader";
import { useNuvoContext } from "../../context/NuvoContext";

export type MyProfileInitialValuesType = {
  name: string;
  email: string;
  phoneNumber: string;
  streetName: string;
  pincode: string;
  landMark: string;
  doorNo: string;
  city: string;
  state: string;
};

export const MyProfileFlyIn: React.FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  const [userData, setUserData] = useState<UserDetailsType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<MyProfileInitialValuesType>({
    name: "",
    email: "",
    phoneNumber: "",
    streetName: "",
    pincode: "",
    landMark: "",
    doorNo: "",
    city: "",
    state: "",
  });
  const [errors, setErrors] = useState<Partial<MyProfileInitialValuesType>>({});
  const { handleError } = useNuvoContext();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        const data = await getMyDetails(user.token);
        setUserData(data);
        if (data?.user && data?.address) {
          setFormValues({
            name: data.user.name || "",
            email: data.user.email || "",
            phoneNumber: data.user.phoneNumber?.toString() || "",
            streetName: data.address[0]?.streetName || "",
            pincode: data.address[0]?.pincode || "",
            landMark: data.address[0]?.landMark || "",
            doorNo: data.address[0]?.doorNo || "",
            city: data.address[0]?.city || "",
            state: data.address[0]?.state || "",
          });
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
    // @ts-ignore
  }, []);

  const { setRender } = useNuvoContext();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name Field Required"),
    email: Yup.string().required("Email Field Required").email("Invalid email"),
    phoneNumber: Yup.string().required("Phone Number Field Required"),
    streetName: Yup.string().required("Street name Required"),
    pincode: Yup.string().required("Pincode Required"),
    landMark: Yup.string().optional().nullable(),
    doorNo: Yup.string().required("Door Number Required"),
    city: Yup.string().required("city name Required"),
    state: Yup.string().required("state name Required"),
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newErrors: Partial<MyProfileInitialValuesType> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            newErrors[err.path as keyof MyProfileInitialValuesType] =
              err.message;
          }
        });
        setErrors(newErrors);
      } else {
        console.error("Unexpected error during validation:", error);
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        try {
          const response = await editUserHandler(
            formValues.name,
            formValues.email,
            Number(formValues.phoneNumber),
            user.token,
            formValues.streetName,
            formValues.pincode,
            formValues.landMark,
            formValues.doorNo,
            formValues.city,
            formValues.state,
            userData?.address[0]?._id
          );
          if (response) {
            toast.success("User Details Updated Successfully");
            setRender((prev) => !prev);
            setIsOpen(false);
          }
        } catch (error) {
          handleError(error);
        }
      } else {
        toast.error("User credentials not found");
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col gap-1 justify-center items-center h-screen overflow-hidden">
        <Loader />
      </div>
    );
  }

  return (
    <NuvoDrawer title="My Profile" isOpen={isOpen} setIsOpen={setIsOpen}>
      {userData?.user && userData?.address && (
        <div className="h-[calc(90vh-100px)] p-6 space-y-4 mx-auto overflow-y-auto">
          <div className="rounded-[12px] bg-nuvo-grey shadow-md flex flex-col gap-5 p-4">
            <p className="text-title-6 text-black font-medium">User Details</p>
            <div className="flex flex-col gap-2 text-black">
              {["name", "email", "phoneNumber"].map((field) => (
                <div key={field} className="flex flex-col gap-2">
                  <label htmlFor={field} className="text-lg font-medium">
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    id={field}
                    name={field}
                    type={field === "email" ? "email" : "text"}
                    value={
                      formValues[field as keyof MyProfileInitialValuesType]
                    }
                    onChange={handleInputChange}
                    className="h-10 rounded-lg border px-3 focus:outline-none focus:border-blue-500"
                  />
                  {errors[field as keyof MyProfileInitialValuesType] && (
                    <div className="text-red-500 text-sm font-medium">
                      {errors[field as keyof MyProfileInitialValuesType]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {userData.address.length > 0 && (
            <div className="rounded-[12px] bg-nuvo-grey text-black flex flex-col gap-5 p-4">
              <p className="text-title-6 text-black font-medium">
                Address Details
              </p>
              {[
                "streetName",
                "doorNo",
                "state",
                "city",
                "pincode",
                "landMark",
              ].map((field) => (
                <div key={field} className="flex flex-col gap-2">
                  <label className="text-body-1 font-medium" htmlFor={field}>
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    className="h-10 border border-black rounded-[8px] p-2"
                    type={field === "pincode" ? "number" : "text"}
                    id={field}
                    name={field}
                    value={
                      formValues[field as keyof MyProfileInitialValuesType]
                    }
                    onChange={handleInputChange}
                  />
                  {errors[field as keyof MyProfileInitialValuesType] && (
                    <div className="text-danger text-body-1 font-medium">
                      {errors[field as keyof MyProfileInitialValuesType]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="border-grey-200 fixed bottom-0 left-0 right-0 flex flex-row gap-2 border-t-2 bg-white px-7 py-3 lg:absolute">
            <button
              className="text-white hover:bg-success hover:text-black rounded-[8px] bg-black py-2 w-full font-medium text-title-6"
              onClick={handleSubmit}
            >
              Save Changes
            </button>
            <button
              className="text-danger hover:bg-danger rounded-[8px] hover:text-white py-2 w-full font-medium text-title-6"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </NuvoDrawer>
  );
};
