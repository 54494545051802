
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Manufacturer = () => {
 

  return (
    <div className="flex flex-col gap-20 w-10/12 mx-auto md:my-20 my-10">
      <div
      
        className="grid grid-cols-1 md:grid-cols-2 gap-5"
      >
        <LazyLoadImage
          src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728052098/rakwbcl9p0n2vvzekjyh.webp"}
          alt="Sterilization"
          effect="blur"
          loading="lazy"
          className="hidden md:block w-11/12"
        />
        <div className="text-base md:text-lg flex flex-col gap-5">
          <div>
            <p className="text-2xl md:text-3xl lg:text-4xl">Manufacturing Unit</p>
            <hr className="border border-black w-[200px] mt-2" />
          </div>
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728052098/rakwbcl9p0n2vvzekjyh.webp"}
            alt="Sterilization"
            effect="blur"
            loading="lazy"
            className="md:hidden w-11/12"
          />
          <div
            // variants={itemVariants}
            className="text-base lg:text-xl text-slate-500 flex flex-col gap-3"
          >
            <p className="text-justify">
              Nuvo Medsurg has developed its infrastructure to manufacture high
              quality products. For manufacturing sutures we have designed ISO
              Class-7 Clean Rooms for Non Absorbable Sutures (it meets Class
              10000 cleanliness standards approved as per international
              requirements). We have also designed and developed ISO-6 Clean
              Rooms for its Absorbable Sutures.
            </p>
            <p className="text-justify">
              Our infrastructure is developed as per international regulatory
              agencies. Our plant ensures non polluted and non contaminated
              environment for quality production. The process ensures less
              toxins before sterilization and gives desired quality product post
              sterilization.
            </p>
          </div>
        </div>
      </div>

      <div
        className="grid grid-cols-1 md:grid-cols-2 gap-5"
      >
        <div
          className="text-base lg:text-xl text-slate-500 flex flex-col gap-5"
        >
          <div>
            <p className="text-2xl md:text-3xl lg:text-4xl">Sterilization</p>
            <hr className="border border-black w-[200px] mt-2" />
          </div>
          <LazyLoadImage
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053478/grg1ngdgsees8ucypy7q.webp"}
            alt="Manufacturing"
            effect="blur"
            loading="lazy"
            className="md:hidden w-11/12"
          />
          <p className="text-justify">
            One of the more difficult aspects of suture manufacturing has been
            the sterilization of synthetic absorbable sutures.
          </p>
          <p className="text-justify">
            Due to their hygroscopic nature and the tendency to bond effectively
            with EtO in the presence of moisture, they tend to degenerate and
            lose strength on storage, leading to serious implications for the
            patient and the surgeon.
          </p>
        </div>
        <LazyLoadImage
          src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728052092/jkenrkmxnb5ktdbn7ian.webp"}
          alt="Manufacturing"
          effect="blur"
          loading="lazy"
          className="hidden md:block w-11/12"
        />
      </div>
    </div>
  );
};
