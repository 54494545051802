import React, { createContext, useContext, useState, useEffect } from "react";
import { getLocalStorage } from "../helpers/authHelper"; // Adjust the import path as needed

interface CartContextType {
  onClickCart: boolean;
  setOnclickStart: React.Dispatch<React.SetStateAction<boolean>>;
  cartItemsCount: number;
  updateCartItemsCount: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [onClickCart, setOnclickStart] = useState<boolean>(false);
  const [cartItemsCount, setCartItemsCount] = useState<number>(0);

  const updateCartItemsCount = () => {
    const cartItems = getLocalStorage("nuvo_cart") || [];
    setCartItemsCount(Array.isArray(cartItems) ? cartItems.length : 0);
  };

  useEffect(() => {
    updateCartItemsCount();
  }, []);

  return (
    <CartContext.Provider value={{ onClickCart, setOnclickStart, cartItemsCount, updateCartItemsCount }}>
      {children}
    </CartContext.Provider>
  );
};