import { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { experienceServices } from "../../services/experience.services";
import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import DOMPurify from "dompurify";


const testimonials = [
  {
    name: "Gaurav Goel",
    text: "<h2>100 % quality product ..</h2><p>Nuvo medsurg Pvt Ltd Is good company...</p>",
  },
  {
    name: "Anand Mani",
    text: "<p>100% high quality products surgical sutures eexellent</p>",
  },
  {
    name: "Jyoti Phogat",
    text: "<p>100% High-Quality Products! The surgical sutures are excellent. Very reliable and durable. Highly recommended!</p>",
  },
  {
    name: "Suryakant Tiwari",
    text: "<p>Outstanding quality surgical sutures.</p><p> 100% high-quality products and excellent service.</p> <p>Very satisfied with my purchase.</P>",
  },
  {
    name: "vivek dhalwal",
    text: "<p>The sutures are very good quality. Excellent products.</p><p> Will buy again.</p>",
  },
  {
    name: "Rajesh Kumar",
    text: "<p>Very impressed with the quality of the surgical sutures.</p><p>Excellent products. Highly recommend.</p>",
  },
];

export default function Testimonials() {
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? experienceServices.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) =>
      prevIndex === experienceServices.length - 1 ? 0 : prevIndex + 1
    );
  };

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const currentItem = testimonials[currentIndex];

  return (
    <div>
      <p className="text-2xl md:text-3xl lg:text-4xl font-medium text-center mb-10">
        What Our Clients Say
      </p>
      <div className="w-full h-screen relative overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={"https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053869/elozpu8pvazrgb1mvica.webp"}
            alt="Background"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>
        <div ref={contentRef} className="absolute inset-0 z-10">
          <div className="container mx-auto px-4 h-full lg:py-8 flex flex-col justify-center items-center relative">
            <div className="absolute top-20 right-10  lg:top-20 lg:right-20 flex gap-2 md:gap-4">
              {currentIndex !== 0 && (
                <button
                  onClick={handlePrev}
                  className="p-3  border border-white md:border-2 rounded-full"
                >
                  <img
                    src={leftArrow}
                    alt="Previous"
                    className="w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6"
                  />
                </button>
              )}
              {currentIndex < testimonials.length - 1 && (
                <button
                  onClick={handleNext}
                  className="p-3 border border-white md:border-2 rounded-full"
                >
                  <img
                    src={rightArrow}
                    alt="Next"
                    className="w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6"
                  />
                </button>
              )}
            </div>
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={currentIndex}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                className="flex flex-col gap-4 md:gap-6 lg:gap-10 text-white w-full  absolute"
              >
                <Testimonial name={currentItem.name} text={currentItem.text} />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}

interface TestimonialProps {
  name: string;
  text: string;
}

const Testimonial: React.FC<TestimonialProps> = ({ name, text }) => {
  const sanitizedText = DOMPurify.sanitize(text);

  return (
    <div className="flex flex-col gap-2 md:gap-3 lg:gap-4 text-center">
      <h2 className="text-xl md:text-2xl font-medium">{name}</h2>
      <div
        className="text-lg md:text-xl text-slate-400"
        dangerouslySetInnerHTML={{ __html: sanitizedText }}
      />
    </div>
  );
};
