
import { Link } from "react-router-dom";

const ErrorPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h2 className="text-4xl font-bold mb-4">Oops! Something went wrong</h2>
      <p className="text-xl mb-8">
        It's not you, it's us. Please try again later.
      </p>
      <Link
        to="/"
        className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
      >
        Go back to homepage
      </Link>
    </div>
  );
};

export default ErrorPage;
