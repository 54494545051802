import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { LoginResponse } from "../types/user.types";

type NuvoContextType = {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  user: LoginResponse | null;
  setUser: Dispatch<SetStateAction<LoginResponse | null>>;
  handleError: (error: unknown) => void;
};

export const NuvoContext = createContext<NuvoContextType | undefined>(
  undefined
);

export const useNuvoContext = (): NuvoContextType => {
  const context = useContext(NuvoContext);
  if (context === undefined) {
    throw new Error("useRender must be used within a RenderProvider");
  }
  return context;
};
