import { useNavigate } from "react-router-dom";
import { ArrowUprightIcon } from "../../assets/icons/ArroUpRightIcon";
import { CategoryDataType } from "../../types/category.types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Suspense } from "react";
import Shimmer from "../Shimmer/Shimmer";

export const FeaturedCategories = ({ data }: { data: CategoryDataType }) => {
  const navigate = useNavigate();

  const filteredData = data
    .filter((d) => ["STERILE DRESSINGS", "ADHESIVE TAPES"].includes(d.name))
    .sort((a, b) => {
      const order = ["STERILE DRESSINGS", "ADHESIVE TAPES"];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });
  return (
    <div className="w-11/12 mx-auto flex flex-col gap-4">
      <p className="text-title-5 font-medium">Featured Categories</p>
      <div className="grid grid-cols-2 gap-10 items-center">
        <div className="grid grid-cols-2 gap-4">
          {filteredData?.map((category) => {
            return (
              <Suspense fallback={ <div
                key={category.name}
                className="cursor-pointer bg-nuvo-card shadow-md min-w-[350px] md:min-w-[450px] flex-1 flex p-6 flex-col items-center rounded-[8px]"
              >
                <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
              </div>}>
              <div
              key={category.name}
                onClick={() => navigate(`/categories/${category.slug}`)}
                className=" bg-nuvo-card shadow-md flex-1 w-[300px] flex flex-col items-center rounded-[8px]"
              >
                
                <div className="flex-shrink-0 h-[250px] w-[250px] flex justify-center items-center">
                 
                  <LazyLoadImage
                    className="w-full h-full object-cover"
                    src={category.image}
                    alt={category.name}
                    effect="blur"
                    loading="lazy"
                  />
                 
                </div>
                <p className="font-medium text-title-6">{category.name}</p>
               
              </div>
              </Suspense>
            );
          })}
        </div>
        <div
          onClick={() => navigate(`/categories`)}
          className="cursor-pointer bg-black w-8/12 text-center align-middle justify-center py-3 rounded-[8px] px-6 text-white font-medium text-title-7 flex flex-row gap-2 items-center h-fit"
        >
          <p>Check Other Categories</p>
          <ArrowUprightIcon />
        </div>
      </div>
    </div>
  );
};
